import type { FunctionComponent } from 'react'
import type { HTMLElementProps } from '~/types'
import { cn } from '~/utils'
import type { SanityStyles } from './blocks/PortableText/types'

export interface HeadingProps extends HTMLElementProps<'h1'> {
	type: Extract<
		SanityStyles,
		`${'display' | 'heading' | 'title'}${'Xxl' | 'Xl' | 'Lg' | 'Md' | 'Sm' | 'Xs'}` | `${'h' | 'display'}${number}`
	>
}

export interface ParagraphProps extends HTMLElementProps<'p'> {
	type: Extract<
		SanityStyles,
		| `${'text' | 'body' | 'overline'}${'Xxl' | 'Xl' | 'Lg' | 'Md' | 'Sm' | 'Xs'}`
		| 'lead'
		| 'footnote'
		| 'body'
		| 'small'
	>
}

const HeaderTag: FunctionComponent<HeadingProps> = ({ type, className, ...props }) => {
	switch (type) {
		case 'displayXxl':
		case 'headingLg':
			return <h1 className={cn(className)} {...props} />

		case 'displayXl':
		case 'headingMd':
			return <h2 className={cn(className)} {...props} />

		case 'displayLg':
		case 'headingSm':
			return <h3 className={cn(className)} {...props} />

		case 'displayMd':
		case 'titleLg':
			return <h4 className={cn(className)} {...props} />

		case 'displaySm':
		case 'titleMd':
			return <h5 className={cn(className)} {...props} />

		case 'displayXs':
		case 'titleSm':
			return <h6 className={cn(className)} {...props} />

		/**
		 * @deprecated
		 */
		case 'display1':
			return <h1 className={cn('text-9 md:text-11 font-serif', className)} {...props} />
		case 'display2':
			return <h2 className={cn('text-8 md:text-10 font-serif', className)} {...props} />
		case 'display3':
			return <h3 className={cn('text-7 md:text-9 font-serif', className)} {...props} />
		case 'h1':
			return <h1 className={cn('text-7 md:text-8 font-serif', className)} {...props} />
		case 'h2':
			return <h2 className={cn('text-6 md:text-7 font-serif', className)} {...props} />
		case 'h3':
			return <h3 className={cn('text-6 font-serif', className)} {...props} />
		case 'h4':
			return <h4 className={cn('text-5', className)} {...props} />
		case 'h5':
			return <h5 className={cn('text-4', className)} {...props} />
		case 'h6':
			return <h6 className={cn('text-3', className)} {...props} />
	}
}
export const Heading: FunctionComponent<HeadingProps> = ({ className, id, children, ...props }) => {
	return (
		<HeaderTag className={cn(getHeaderClasses(props.type), 'tracking-[-0.01em]', className)} id={id} {...props}>
			{id && <a id={`a-${id}`} className="invisible absolute -top-20" />}
			{children}
		</HeaderTag>
	)
}

export const Paragraph: FunctionComponent<ParagraphProps> = ({ className, ...props }) => (
	<p className={cn(getParagraphClasses(props.type), 'font-sans tracking-[-0.01em]', className)} {...props} />
)

// Classnames are defined by tailwind.config.ts. Eg. tailwind.config.ts -> fontSize defines
// { '11': '112px', '10': '84px', ... } which enables classes text-11, text-10 ++ for sizes.
const getHeaderClasses = (type: HeadingProps['type']) => {
	switch (type) {
		case 'displayXxl':
			return 'text-11 leading-heading font-serif font-normal'
		case 'displayXl':
			return 'text-10 leading-heading font-serif font-normal'
		case 'displayLg':
			return 'text-9 leading-heading font-serif font-normal'
		case 'displayMd':
			return 'md:text-8 text-6 leading-heading font-serif font-normal'
		case 'displaySm':
			return 'md:text-7 text-6 leading-heading font-serif font-normal'
		case 'displayXs':
			return 'text-6 leading-heading font-serif font-normal'

		case 'headingLg':
			return 'text-7 leading-default font-sans font-medium'
		case 'headingMd':
			return 'text-6 leading-default font-sans font-medium'
		case 'headingSm':
			return 'md:text-5 text-4 leading-default font-sans font-medium'

		case 'titleLg':
			return 'text-4 leading-default font-sans font-medium'
		case 'titleMd':
			return 'text-3 leading-default font-sans font-medium'
		case 'titleSm':
			return 'text-2 leading-default font-sans font-medium'
	}
}

export const getParagraphClasses = (type: ParagraphProps['type']) => {
	switch (type) {
		case 'textXl':
			return 'md:text-5 text-4 leading-default font-normal'
		case 'textLg':
			return 'text-4 leading-default font-normal'
		case 'textMd':
			return 'text-3 leading-default font-normal'
		case 'textSm':
			return 'text-2 leading-default font-normal'

		case 'bodyXl':
			return 'text-5 leading-body font-normal'
		case 'bodyLg':
			return 'md:text-4 text-3 leading-body font-normal'
		case 'bodyMd':
			return 'md:text-3 text-2 leading-body font-normal'
		case 'bodySm':
			return 'text-2 leading-body font-normal'

		case 'overlineMd':
			return 'md:text-2 text-1 leading-default font-medium uppercase tracking-[0.06em]'
		case 'overlineSm':
			return 'text-1 leading-default font-medium uppercase tracking-[0.06em]'

		case 'lead':
			return 'text-5 leading-body font-normal'
		case 'footnote':
			return 'text-2 leading-body font-normal'
	}
}

// deprecated typography names
export const Body: FunctionComponent<HTMLElementProps<'p'>> = ({ className, ...props }) => (
	<p className={cn('leading-body text-4', className)} {...props} />
)
export const Small: FunctionComponent<HTMLElementProps<'p'>> = ({ className, ...props }) => (
	<p className={cn('leading-body text-3', className)} {...props} />
)
