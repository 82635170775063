import type { CheckoutResponse, FormValues } from '~/types'
import { createFormField } from './FormUtils'

export type LoginFormValues = FormValues<typeof LOGIN_FORM_FIELDS>

export const LOGIN_FORM_FIELDS = {
	...createFormField({
		type: 'email',
		name: 'email',
		label: 'Email',
		placeholder: 'nomono@nomono.co',
		required: true
	}),
	...createFormField({
		type: 'password',
		name: 'password',
		label: 'Password',
		placeholder: '*******',
		autoComplete: 'on'
	})
} as const

export function getLoginFormValues(checkout: CheckoutResponse): Required<LoginFormValues> {
	const properties = checkout.properties
	return {
		email: properties.shipping_email ?? '',
		password: ''
	}
}

export function getDefaultLoginFormValues(): Required<LoginFormValues> {
	return {
		email: '',
		password: ''
	}
}
