/* eslint-disable @typescript-eslint/no-namespace */

/**
 * Collection of all Stripe supported VAT IDs.
 * From https://stripe.com/docs/invoicing/taxes/account-tax-ids?dashboard-or-api=api#supported-tax-id
 */
export namespace VatID {
	export enum AU {
		/**
		 * Australian Business Number (AU ABN)
		 * @example 12345678912
		 */
		au_abn = 'au_abn',
		/**
		 * Australian Taxation Office Reference Number
		 * @example 123456789123
		 */
		au_arn = 'au_arn'
	}
	export enum AT {
		/**
		 * European VAT number
		 * @example ATU12345678
		 */
		eu_vat = 'eu_vat'
	}
	export enum BE {
		/**
		 * European VAT number
		 * @example BE0123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum BR {
		/**
		 * Brazilian CNPJ number
		 * @example 01.234.456/5432-10
		 */
		br_cnpj = 'br_cnpj',

		/**
		 * Brazilian CPF number
		 * @example 123.456.789-87
		 */
		br_cpf = 'br_cpf'
	}
	export enum BG {
		/**
		 * Bulgaria Unified Identification Code
		 * @example 123456789
		 */
		bg_uic = 'bg_uic',

		/**
		 * European VAT number
		 * @example BG0123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum CA {
		/**
		 * Canadian BN
		 * @example 123456789
		 */
		ca_bn = 'ca_bn',
		/**
		 * Canadian GST/HST number
		 * @example 123456789RT0002
		 */
		ca_gst_hst = 'ca_gst_hst',

		/**
		 * Canadian PST number (British Columbia)
		 * @example PST-1234-5678
		 */
		ca_pst_bc = 'ca_pst_bc',

		/**
		 * Canadian PST number (Manitoba)
		 * @example 123456-7
		 */
		ca_pst_mb = 'ca_pst_mb',

		/**
		 * Canadian PST number (Saskatchewan)
		 * @example 1234567
		 */
		ca_pst_sk = 'ca_pst_sk',

		/**
		 * Canadian QST number (Québec)
		 * @example 1234567890TQ1234
		 */
		ca_qst = 'ca_qst'
	}
	export enum CL {
		/**
		 * Chilean TIN
		 * @example 12.345.678-K
		 */
		cl_tin = 'cl_tin'
	}
	export enum HR {
		/**
		 * European VAT number
		 * @example HR12345678912
		 */
		eu_vat = 'eu_vat'
	}
	export enum CY {
		/**
		 * European VAT number
		 * @example CY12345678Z
		 */
		eu_vat = 'eu_vat'
	}
	export enum CZ {
		/**
		 * European VAT number
		 * @example CZ1234567890
		 */
		eu_vat = 'eu_vat'
	}
	export enum DK {
		/**
		 * European VAT number
		 * @example DK12345678
		 */
		eu_vat = 'eu_vat'
	}
	export enum EG {
		/**
		 * Egyptian Tax Identification Number
		 * @example 123456789
		 */
		eg_tin = 'eg_tin'
	}
	export enum EE {
		/**
		 * European VAT number
		 * @example EE123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum EU {
		/**
		 * European One Stop Shop VAT number for non-Union scheme
		 * @example EU123456789
		 */
		eu_oss_vat = 'eu_oss_vat'
	}
	export enum FI {
		/**
		 * European VAT number
		 * @example FI12345678
		 */
		eu_vat = 'eu_vat'
	}
	export enum FR {
		/**
		 * European VAT number
		 * @example FRAB123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum GE {
		/**
		 * Georgian VAT
		 * @example 123456789
		 */
		ge_vat = 'ge_vat'
	}
	export enum DE {
		/**
		 * European VAT number
		 * @example DE123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum EL {
		/**
		 * European VAT number
		 * @example EL123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum HK {
		/**
		 * Hong Kong BR number
		 * @example 12345678
		 */
		hk_br = 'hk_br'
	}
	export enum HU {
		/**
		 * Hungary tax number (adószám)
		 * @example 12345678-1-23
		 */
		hu_tin = 'hu_tin',

		/**
		 * European VAT number
		 * @example HU12345678912
		 */
		eu_vat = 'eu_vat'
	}
	export enum IS {
		/**
		 * Icelandic VAT
		 * @example 123456
		 */
		is_vat = 'is_vat'
	}
	export enum IN {
		/**
		 * Indian GST number
		 * @example 12ABCDE3456FGZH
		 */
		in_gst = 'in_gst'
	}
	export enum ID {
		/**
		 * Indonesian NPWP number
		 * @example 12.345.678.9-012.345
		 */
		id_npwp = 'id_npwp'
	}
	export enum IE {
		/**
		 * European VAT number
		 * @example IE1234567AB
		 */
		eu_vat = 'eu_vat'
	}
	export enum IL {
		/**
		 * Israel VAT
		 * @example 000012345
		 */
		il_vat = 'il_vat'
	}
	export enum IT {
		/**
		 * European VAT number
		 * @example IT12345678912
		 */
		eu_vat = 'eu_vat'
	}
	export enum JP {
		/**
		 * Japanese Corporate Number (*Hōjin Bangō*)
		 * @example 1234567891234
		 */
		jp_cn = 'jp_cn',
		/**
		 * Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)
		 * @example 12345
		 */
		jp_rn = 'jp_rn',
		/**
		 * Japanese Tax Registration Number (*Tōroku Bangō*)
		 * @example T1234567891234
		 */
		jp_trn = 'jp_trn'
	}
	export enum KE {
		/**
		 * Kenya Revenue Authority Personal Identification Number
		 * @example P000111111A
		 */
		ke_pin = 'ke_pin'
	}
	export enum LV {
		/**
		 * European VAT number
		 * @example LV12345678912
		 */
		eu_vat = 'eu_vat'
	}
	export enum LI {
		/**
		 * Liechtensteinian UID number
		 * @example CHE123456789
		 */
		li_uid = 'li_uid'
	}
	export enum LT {
		/**
		 * European VAT number
		 * @example LT123456789123
		 */
		eu_vat = 'eu_vat'
	}
	export enum LU {
		/**
		 * European VAT number
		 * @example LU12345678
		 */
		eu_vat = 'eu_vat'
	}
	export enum MY {
		/**
		 * Malaysian FRP number
		 * @example 12345678
		 */
		my_frp = 'my_frp',
		/**
		 * Malaysian ITN
		 * @example C 1234567890
		 */
		my_itn = 'my_itn',
		/**
		 * Malaysian SST number
		 * @example A12-3456-78912345
		 */
		my_sst = 'my_sst'
	}
	export enum MT {
		/**
		 * European VAT number
		 * @example MT12345678
		 */
		eu_vat = 'eu_vat'
	}
	export enum MX {
		/**
		 * Mexican RFC number
		 * @example ABC010203AB9
		 */
		mx_rfc = 'mx_rfc'
	}
	export enum NL {
		/**
		 * European VAT number
		 * @example NL123456789B12
		 */
		eu_vat = 'eu_vat'
	}
	export enum NZ {
		/**
		 * New Zealand GST number
		 * @example 123456789
		 */
		nz_gst = 'nz_gst'
	}
	export enum NO {
		/**
		 * Norwegian VAT number
		 * @example 123456789MVA
		 */
		no_vat = 'no_vat'
	}
	export enum PH {
		/**
		 * Philippines Tax Identification Number
		 * @example 123456789012
		 */
		ph_tin = 'ph_tin'
	}
	export enum PL {
		/**
		 * European VAT number
		 * @example PL1234567890
		 */
		eu_vat = 'eu_vat'
	}
	export enum PT {
		/**
		 * European VAT number
		 * @example PT123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum RO {
		/**
		 * European VAT number
		 * @example RO1234567891
		 */
		eu_vat = 'eu_vat'
	}
	export enum RU {
		/**
		 * Russian INN
		 * @example 1234567891
		 */
		ru_inn = 'ru_inn',
		/**
		 * Russian KPP
		 * @example 123456789
		 */
		ru_kpp = 'ru_kpp'
	}
	export enum SA {
		/**
		 * Saudi Arabia VAT
		 * @example 123456789012345
		 */
		sa_vat = 'sa_vat'
	}
	export enum SG {
		/**
		 * Singaporean GST
		 * @example M12345678X
		 */
		sg_gst = 'sg_gst',
		/**
		 * Singaporean UEN
		 * @example 123456789F
		 */
		sg_uen = 'sg_uen'
	}
	export enum SK {
		/**
		 * European VAT number
		 * @example SK1234567891
		 */
		eu_vat = 'eu_vat'
	}
	export enum SI {
		/**
		 * Slovenia tax number (davčna številka)
		 * @example 12345678
		 */
		si_tin = 'si_tin',
		/**
		 * European VAT number
		 * @example SI12345678
		 */
		eu_vat = 'eu_vat'
	}
	export enum ZA {
		/**
		 * South African VAT number
		 * @example 4123456789
		 */
		za_vat = 'za_vat'
	}
	export enum KR {
		/**
		 * Korean BRN
		 * @example 123-45-67890
		 */
		kr_brn = 'kr_brn'
	}
	export enum ES {
		/**
		 * Spanish NIF number (previously Spanish CIF number)
		 * @example A12345678
		 */
		es_cif = 'es_cif',
		/**
		 * European VAT number
		 * @example ESA1234567Z
		 */
		eu_vat = 'eu_vat'
	}
	export enum SE {
		/**
		 * European VAT number
		 * @example SE123456789123
		 */
		eu_vat = 'eu_vat'
	}
	export enum CH {
		/**
		 * Switzerland VAT number
		 * @example CHE-123.456.789 MWST
		 */
		ch_vat = 'ch_vat'
	}
	export enum TW {
		/**
		 * Taiwanese VAT
		 * @example 12345678
		 */
		tw_vat = 'tw_vat'
	}
	export enum TH {
		/**
		 * Thai VAT
		 * @example 1234567891234
		 */
		th_vat = 'th_vat'
	}
	export enum TR {
		/**
		 * Turkish Tax Identification Number
		 * @example 0123456789
		 */
		tr_tin = 'tr_tin'
	}
	export enum UA {
		/**
		 * Ukrainian VAT
		 * @example 123456789
		 */
		ua_vat = 'ua_vat'
	}
	export enum AE {
		/**
		 * United Arab Emirates TRN
		 * @example 123456789012345
		 */
		ae_trn = 'ae_trn'
	}
	export enum GB {
		/**
		 * United Kingdom VAT number
		 * @example GB123456789
		 */
		gb_vat = 'gb_vat',
		/**
		 * Northern Ireland VAT number
		 * @example XI123456789
		 */
		eu_vat = 'eu_vat'
	}
	export enum US {
		/**
		 * United States EIN
		 * @example 12-3456789
		 */
		us_ein = 'us_ein'
	}
}
