import { colors } from '@nomonosound/gravity'
import { isObject } from 'lodash'
import type { ButtonProps } from '~/components/Button'
import type { Theme, ThemeSettings } from '~/types'
import type { StyledTheme } from '../ThemeProvider'

export interface GetColorProps {
	theme: Theme | StyledTheme | undefined
}

type FgTheme = Exclude<ThemeSettings['color'], 'theme'>
type BgTheme = Exclude<ThemeSettings['backgroundColor'], 'theme'>

function getTheme({ theme }: GetColorProps, type: 'bg'): Theme | BgTheme
function getTheme({ theme }: GetColorProps, type: 'foreground'): Theme | FgTheme
function getTheme({ theme }: GetColorProps, type: 'foreground' | 'bg'): Theme | FgTheme | BgTheme {
	if (!theme) return undefined
	if (isObject(theme)) {
		switch (type) {
			case 'foreground':
				return !theme.settings?.color || theme.settings?.color === 'theme'
					? theme.current
					: theme.settings.color
			case 'bg':
				return !theme.settings?.backgroundColor || theme.settings?.backgroundColor === 'theme'
					? theme.current
					: theme.settings.backgroundColor
		}
	}
	return theme
}

function getCustomColor(theme: GetColorProps['theme']): string {
	return isObject(theme) && theme.settings?.customColor ? theme.settings?.customColor : 'transparent'
}

function getcustomBackground(theme: GetColorProps['theme']): string {
	return isObject(theme) && theme.settings?.customBackgroundColor
		? theme.settings?.customBackgroundColor
		: 'transparent'
}

export function getColor(props: GetColorProps): string {
	const theme = getTheme(props, 'foreground')
	switch (theme) {
		case 'dark':
			return '#F7F5F2'

		case 'primary':
		case 'white':
		case 'light':
			return '#1F1F21'

		case 'inherit':
		case undefined:
			return 'inherit'

		case 'custom':
			return getCustomColor(props.theme)
		default:
			return `var(--text-${theme})`
	}
}

export function getBorderColor(props: GetColorProps): string {
	const theme = getTheme(props, 'foreground')
	switch (theme) {
		case 'white':
		case 'light':
		case 'primary':
			return 'rgba(0,0,0,0.08)'

		case 'dark':
		case 'inherit':
		case undefined:
			return 'transparent'

		case 'custom':
			return getCustomColor(props.theme)
		default:
			return `var(--text-${theme})`
	}
}

export function getSeparatorColor(props: GetColorProps): string {
	const theme = getTheme(props, 'foreground')
	switch (theme) {
		case 'white':
		case 'light':
		case 'dark':
		case 'inherit':
		case undefined:
			return colors.gray50

		case 'primary':
			return 'rgba(31, 31, 33, 0.5)'

		case 'custom':
			return getCustomColor(props.theme)
		default:
			return `var(--text-${theme})`
	}
}

export function getSecondaryColor(props: GetColorProps): string {
	const theme = getTheme(props, 'foreground')
	switch (theme) {
		case 'dark':
			return colors.blue30

		case 'primary':
		case 'white':
		case 'light':
			return colors.gray70

		case 'inherit':
		case undefined:
			return 'inherit'

		case 'custom':
			return getCustomColor(props.theme)
		default:
			return `var(--text-${theme})`
	}
}

export function getButtonTextColor(props: GetColorProps): string {
	const theme = getTheme(props, 'foreground')
	switch (theme) {
		case 'primary':
		case 'dark':
			return 'var(--text-on-light)'

		case 'light':
		case 'white':
			return colors.white

		case 'inherit':
		case undefined:
			return 'inherit'

		case 'custom':
			return getCustomColor(props.theme)
		default:
			return `var(--text-${theme})`
	}
}

export function getButtonColor(props: GetColorProps & ButtonProps): string {
	switch (props.color) {
		case 'error':
			return colors.yellow70

		case 'primary':
		default:
			return 'var(--primary)'
	}
}

export function getBackgroundColor(props: GetColorProps): string {
	const theme = getTheme(props, 'bg')
	switch (theme) {
		case 'primary':
			return 'var(--primary)'

		case 'dark':
			return '#1F1F21'

		case 'white':
			return '#FFFEFD'

		case 'light':
			return '#F7F5F2'

		case 'transparent':
		case 'inherit':
		case undefined:
			return 'transparent'

		case 'custom':
			return getcustomBackground(props.theme)
		default:
			return `var(--background-${theme})`
	}
}

export function getInputBorder(props: GetColorProps): string {
	const theme = getTheme(props, 'foreground')
	switch (theme) {
		case 'primary':
		case 'dark':
			return colors.gray90

		case 'light':
		case 'white':
		case 'inherit':
		case undefined:
			return colors.gray30

		case 'custom':
			return getCustomColor(props.theme)
		default:
			return `var(--text-${theme})`
	}
}

export function getInputBackground(props: GetColorProps): string {
	const theme = getTheme(props, 'bg')
	switch (theme) {
		case 'primary':
		case 'dark':
		case 'light':
		case 'white':
		case 'inherit':
		case undefined:
			return colors.white

		case 'custom':
			return getcustomBackground(props.theme)
		default:
			return `var(--background-${theme})`
	}
}
