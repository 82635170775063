import { useSelector } from 'react-redux'
import { ValidateCouponsDocument } from '~/queries'
import type { State } from '~/types'
import { getCookie } from '../cookie'
import { useGQLQuery } from '../graphql'
import { useMarket } from '../hooks'

export function useCoupon(enabled = true) {
	const checkoutID = useSelector((s: State) => s.checkout.id)
	const market = useMarket()
	const code = getCookie('ref')
	const coupons = useGQLQuery(
		ValidateCouponsDocument,
		{ codes: [code as string], checkoutID, market: market?._id as string },
		{
			enabled: [code, enabled, market?._id].every(Boolean),
			refetchOnMount: false
		}
	)
	return coupons.data?.[0] ?? null
}
