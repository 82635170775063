export * from './segmentCheckout'
export * from './segmentEvent'
export * from './segmentProduct'
export * from './segmentUser'

import { SegmentCheckout, SegmentCheckoutStep } from './segmentCheckout'
import { SegmentEvent } from './segmentEvent'
import { SegmentProduct } from './segmentProduct'

export interface TrackOptions extends SegmentAnalytics.SegmentOpts {}

type ProductListEvents = Extract<`${SegmentEvent}`, `Product List${string}`>
type ProductEvents = Exclude<Extract<`${SegmentEvent}`, `Product ${string}`>, ProductListEvents>

type CheckoutStepEvents = Extract<`${SegmentEvent}`, `Checkout Step ${string}`>

type CheckoutEvents = Exclude<Extract<`${SegmentEvent}`, `Checkout ${string}` | `Order ${string}`>, CheckoutStepEvents>

type OtherEvents =
	| Exclude<`${SegmentEvent}`, ProductListEvents | ProductEvents | CheckoutStepEvents | CheckoutEvents>
	| 'Survey'
	| 'Newsletter Signup'

export type SegmentEventMap = {
	[P in ProductEvents]: SegmentProduct
} & {
	[P in ProductListEvents]: { producs: SegmentProduct[] }
} & {
	[P in CheckoutStepEvents]: SegmentCheckoutStep
} & {
	[P in CheckoutEvents]: SegmentCheckout
} & {
	[P in OtherEvents]: Record<string, any>
}

export type SegmentEvents = keyof SegmentEventMap
