import { HYDRATE } from 'next-redux-wrapper'
import type { LineItem } from '~/types'
import { Action, Actions, MetaAction } from '../actions'

export interface CartState {
	lineItems: LineItem[]
}

const initialState: CartState = {
	lineItems: []
}

function item(state: LineItem, action: MetaAction): LineItem {
	if (action.meta != state.id) return state

	switch (action.type) {
		case 'CART_ITEM_UPDATE':
			return {
				...state,
				product: action.payload
			}

		case 'CART_QUANTITY_UPDATE':
			return {
				...state,
				quantity: action.payload
			}

		default:
			return state
	}
}
export function cart(state: CartState = initialState, action: Action): CartState {
	switch (action.type) {
		case HYDRATE:
			return {
				...state,
				lineItems: action.payload.cart?.lineItems ?? []
			}

		case 'CART_ADD': {
			if (state.lineItems.some((li) => li.id === action.payload.id))
				return {
					...state,
					lineItems: state.lineItems.map((it) =>
						item(it, Actions.updateQuantity(action.payload.quantity, action.payload.id))
					)
				}
			else
				return {
					...state,
					lineItems: [...state.lineItems, action.payload]
				}
		}

		case 'CART_ITEM_UPDATE':
		case 'CART_QUANTITY_UPDATE':
			return {
				...state,
				lineItems: state.lineItems.map((it) => item(it, action))
			}

		case 'CART_REMOVE':
			return {
				...state,
				lineItems: state.lineItems.filter((it) => it.id != action.meta)
			}
	}

	return state
}
