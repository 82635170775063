export const pageLinkFragment = /* groq */ `
	_id,
	_type,
	title,
	slug,
	meta,
	category->
`

export const linkFragment = /* groq */ `
	_type == "link" => {
		pageReference->{
			${pageLinkFragment}
		}
	}
`
