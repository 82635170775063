import { isPresent } from 'ts-extras'

export function setItem<T = any>(key: string, value: T) {
	if (typeof localStorage === 'undefined') return
	if (!localStorage) return null
	if (isPresent(value)) localStorage.setItem(key, JSON.stringify(value))
	else localStorage.removeItem(key)
}

export function getItem<T>(key: string): T | null {
	if (typeof localStorage === 'undefined') return null
	if (!localStorage) return null
	const item = localStorage.getItem(key)
	if (!item) return null
	return JSON.parse(item) as T
}
