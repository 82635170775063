import createImageUrlBuilder from '@sanity/image-url'
import type { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { createCurrentUserHook, createPreviewSubscriptionHook } from 'next-sanity'
import type { SubscriptionOptions } from 'next-sanity/dist/useSubscription'
import type { Except } from 'type-fest'
import type { Block, Dataset, Nullable, Params, SanityKeyed } from '~/types'
import { DATASET, IS_NEXT_PREVIEW } from '../constants'
import { SanityConfig } from './config'

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 **/
export const imageBuilder = (source: SanityImageSource) => createImageUrlBuilder(SanityConfig).image(source)

export interface UseSubscriptionParams<R> extends Except<SubscriptionOptions<R>, 'params' | 'enabled'> {
	params?: Nullable<Params>
	/** The hook will return this on first render.
	 * This is why it's important to fetch *draft* content server-side!
	 */
	initialData: SubscriptionOptions<R>['initialData']
	/**
	 * The passed-down preview context determines whether this function does anything
	 */
	enabled?: Nullable<boolean>

	dataset?: Nullable<Dataset>
}

// Set up the live preview subscription hook
const useProductionHook = createPreviewSubscriptionHook(SanityConfig)
const useDevelopmentHook = createPreviewSubscriptionHook({ ...SanityConfig, dataset: 'development' })
export function usePreviewSubscription<R = any>(query: string, options: UseSubscriptionParams<R>) {
	const enabled = options.enabled ?? IS_NEXT_PREVIEW() ?? false
	const dataset = options.dataset ?? DATASET()
	const params = options.params ?? undefined
	const prodData = useProductionHook(query, {
		...options,
		params,
		enabled: enabled && dataset === 'production'
	})
	const devData = useDevelopmentHook(query, {
		...options,
		params,
		enabled: enabled && dataset === 'development'
	})
	return dataset === 'production' ? prodData : devData
}

// Helper function for using the current logged in user account
export const useCurrentUser = createCurrentUserHook(SanityConfig)

export function isHeading(block: Block) {
	return block.style?.startsWith('h') || block.style?.startsWith('display')
}

export function isBlock(block: any): block is SanityKeyed<Block> {
	return block && block._type === 'block'
}
