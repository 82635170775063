import { combineReducers } from 'redux'
import { cart } from './cart'
import { checkout } from './checkout'
import { market } from './market'

export const rootReducer = () =>
	combineReducers({
		cart,
		checkout,
		market
	})
