import { getCookie } from 'cookies-next'
import { GQL, SegmentEventMap, SegmentEvents, SegmentProduct, SegmentUser, SimpleLineItem, TrackOptions } from '~/types'

export function getUserID(): string | undefined {
	const id = getCookie('hubspotutk', { path: '/' })
	return typeof id === 'string' ? id : undefined
}

export async function identify(data?: SegmentUser, options?: TrackOptions): Promise<void> {
	const id = getUserID()
	if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production')
		return new Promise((resolve) => {
			if (id) window.analytics?.identify(id, data, options, resolve)
			else window.analytics?.identify(data, options, resolve)
		})
}

export async function track<TEvent extends SegmentEvents>(
	event: TEvent,
	data?: SegmentEventMap[TEvent],
	options?: TrackOptions
): Promise<void> {
	if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
		// eslint-disable-next-line no-console
		console.log('tracked', event, data)
	}

	if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production')
		return new Promise((resolve) => {
			if (!window.analytics || !('initialized' in window.analytics)) {
				resolve()
				return
			}
			window.analytics?.track(event, data, options, resolve)
		})
}

export function toAnalyticsProduct(item: GQL.ProductFragment | SimpleLineItem): SegmentProduct {
	const product = 'product' in item ? item.product : item
	return {
		product_id: 'id' in item ? item.id : product._id,
		coupon: getCookie('ref', { path: '/' }) as string,
		name: product.title ?? undefined,
		currency: product.price.currency,
		price: (product.price.unit_amount ?? 0) / 100,
		quantity: 'quantity' in item ? item.quantity : undefined
	}
}
