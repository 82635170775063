/**
 * Segment event names
 * @link https://segment.com/docs/connections/spec/ecommerce/v2/#event-lifecycles
 */
export enum SegmentEvent {
	/** User searched for products */
	ProductsSearched = 'Products Searched',

	/** User viewed a product list or category */
	ProductListViewed = 'Product List Viewed',

	/** User filtered a product list or category */
	ProductListFiltered = 'Product List Filtered',

	/** User viewed promotion */
	PromotionViewed = 'Promotion Viewed',

	/** User clicked on promotion */
	PromotionClicked = 'Promotion Clicked',

	/** User clicked on a product */
	ProductClicked = 'Product Clicked',

	/** User viewed a product details */
	ProductViewed = 'Product Viewed',

	/** User added a product to their shopping cart */
	ProductAdded = 'Product Added',

	/** User removed a product from their shopping cart */
	ProductRemoved = 'Product Removed',

	/** User viewed their shopping cart */
	CartViewed = 'Cart Viewed',

	/** User initiated the order process (a transaction is created) */
	CheckoutStarted = 'Checkout Started',

	/** User viewed a checkout step */
	CheckoutStepViewed = 'Checkout Step Viewed',

	/** User completed a checkout step */
	CheckoutStepCompleted = 'Checkout Step Completed',

	/** User added payment information */
	PaymentInfoEntered = 'Payment Info Entered',

	/** User completed the order */
	OrderCompleted = 'Order Completed',

	/** User updated the order */
	OrderUpdated = 'Order Updated',

	/** User refunded the order */
	OrderRefunded = 'Order Refunded',

	/** User cancelled the order */
	OrderCancelled = 'Order Cancelled',

	/** User entered a coupon on a shopping cart or order */
	CouponEntered = 'Coupon Entered',

	/** Coupon was applied on a user’s shopping cart or order */
	CouponApplied = 'Coupon Applied',

	/** Coupon was denied from a user’s shopping cart or order */
	CouponDenied = 'Coupon Denied',

	/** User removed a coupon from a cart or order */
	CouponRemoved = 'Coupon Removed',

	/** User added a product to the wish list */
	ProductAddedToWishlist = 'Product Added to Wishlist',

	/** User removed a product from the wish list */
	ProductRemovedfromWishlist = 'Product Removed from Wishlist',

	/** User added a wishlist product to the cart */
	WishlistProductAddedToCart = 'Wishlist Product Added to Cart',

	/** Shared a product with one or more friends */
	ProductShared = 'Product Shared',

	/** Shared the cart with one or more friends */
	CartShared = 'Cart Shared',

	/** User reviewed a product */
	ProductReviewed = 'Product Reviewed',

	/** Amazon link clicked */
	AmazonLinkClicked = 'Amazon Link Clicked',

	/** Shipping step started */
	ShippingStepStarted = 'Shipping Step Started',

	/** Billing step started */
	BillingStepStarted = 'Billing Step Started',

	/** Payment step started */
	PaymentStepStarted = 'Payment Step Started',

	/** Shipping step completed */
	ShippingStepCompleted = 'Shipping Step Completed',

	/** Billing Step Completed */
	BillingStepCompleted = 'Billing Step Completed',

	/** Audio Played Homepage */
	AudioPlayedHomepage = 'Audio Played: Homepage',

	/** Audio Played Cloud */
	AudioPlayedCloud = 'Audio Played: Cloud',

	/** Audio Tab Sound Capsule Selected Homepage */
	AudioTabSoundCapsuleSelectedHomepage = 'Audio Tab: Sound Capsule Selected (Homepage)',

	/** Audio Tab Enhanced Selected Homepage */
	AudioTabEnhancedSelectedHomepage = 'Audio Tab: Enhanced Selected (Homepage)',

	/** Audio Tab Spatialized Selected Homepage */
	AudioTabSpatializedSelectedHomepage = 'Audio Tab: Spatialized Selected (Homepage)',

	/** Audio Tab Sound Capsule Selected Cloud */
	AudioTabSoundCapsuleSelectedCloud = 'Audio Tab: Sound Capsule Selected (Cloud)',

	/** Audio Tab Enhanced Selected Cloud */
	AudioTabEnhancedSelectedCloud = 'Audio Tab: Enhanced Selected (Cloud)',

	/** Audio Tab Spatialized Selected Cloud */
	AudioTabSpatializedSelectedCloud = 'Audio Tab: Spatialized Selected (Cloud)'
}
