/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import { twMerge } from 'tailwind-merge'
export { default as classnames } from 'classnames'

/**
 * Generate tailwind compatible classname overrides
 * @param args same arguments as you would use for classnames
 * @returns
 */
export function cn(...args: Parameters<typeof classnames>) {
	return twMerge(classnames(...args))
}
