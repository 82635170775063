import { captureMessage } from '@sentry/nextjs'
import { Auth0DecodedHash, Auth0Error, AuthOptions, DbSignUpOptions, LogoutOptions, Popup, WebAuth } from 'auth0-js'
import { merge } from 'lodash'
import { Component, createContext, PropsWithChildren, useContext } from 'react'
import type { SetOptional } from 'type-fest'
import { AUTH0_CONFIG } from './constants'
import { getItem, setItem } from './storage'

type LoginOptions = Parameters<Popup['loginWithCredentials']>[0] &
	Pick<DbSignUpOptions, 'userMetadata'> &
	({ email: string; password: string } | { realm: 'google-oauth2' })

interface Context {
	client: WebAuth
	options: AuthOptions
	key: string
	accessToken: string | null
	user: auth0.Auth0UserProfile | null
	isAuthenticated: boolean
	error: Auth0Error | null
	loading: boolean
	initializing: boolean

	signup(options: SetOptional<DbSignUpOptions, 'connection'>): Promise<Context['user']>
	login(options: LoginOptions): Promise<Context['user']>
	logout(options?: LogoutOptions): void
}

const intialState: SetOptional<Context, 'client' | 'signup' | 'login' | 'logout'> = {
	options: AUTH0_CONFIG,
	accessToken: null,
	key: 'auth0_token',
	isAuthenticated: false,
	initializing: true,
	loading: true,
	user: null,
	error: null
}

const Auth0Context = createContext<Readonly<Context>>(intialState as Context)

export function useAuth0() {
	return useContext(Auth0Context)
}

export interface AuthProviderProps extends PropsWithChildren {
	options?: AuthOptions

	/** Override storage key (for instance if you need multiple login sessions) */
	tokenKey?: string
}

export class AuthProvider extends Component<AuthProviderProps, Readonly<Context>> {
	constructor(props: AuthProviderProps) {
		super(props)
		const options = merge({}, intialState.options, this.props.options)
		this.state = {
			...intialState,
			key: this.props.tokenKey ?? intialState.key,
			client: new WebAuth(options),
			login: this.login,
			logout: this.logout,
			signup: this.signup,
			options
		}
	}

	private signup: Context['signup'] = (options) =>
		new Promise((resolve, reject) => {
			this.setState({ error: null, loading: true })
			this.state.client.signupAndAuthorize({ connection: AUTH0_CONFIG.connection, ...options }, (error, data) => {
				if (error) {
					this.setState({ error })
					return reject(error)
				}
				const user = data
				resolve(user)
				this.setState({ user })
			})
		})

	private login: Context['login'] = (options) =>
		new Promise((resolve, reject) => {
			this.setState({ error: null, loading: true })
			const realm = 'realm' in options ? options.realm : AUTH0_CONFIG.connection
			if (realm === 'google-oauth2') {
				this.state.client.authorize({
					...this.state.options,
					connection: realm,
					connection_scope: 'email',
					responseMode: 'query',
					responseType: 'code',
					redirectUri: location.toString(),
					...options
				})
				resolve(null)
			} else {
				this.state.client.popup.loginWithCredentials(
					// Realm is not automatically picked up from base settings and is erroneously not included in typings
					{ realm, redirectUri: location.href, ...options } as LoginOptions,
					(error, data) => {
						if (error) {
							this.setState({ error })
							return reject(error)
						}
						const { accessToken, idTokenPayload: user }: Auth0DecodedHash = data
						this.setState({ user, accessToken: accessToken ?? null, isAuthenticated: true })
						resolve(user)
					}
				)
			}
		})

	private logout: Context['logout'] = (options = {}) => {
		this.setState({ error: null, loading: true })
		setItem(this.state.key, '')
		this.state.client.logout({ returnTo: location.href, ...options })
	}

	private async getUserInfo(token: string) {
		return new Promise((resolve, reject) => {
			this.state.client.client.userInfo(token, (err, user) => {
				if (err) {
					setItem(this.state.key, '') // Clear token if there are any errors (e.g. it expired)
					console.warn('userInfo', err)
					reject(err)
				} else {
					this.setState({ accessToken: token, user, isAuthenticated: true })
					resolve(user)
				}
			})
		})
	}

	async componentDidMount() {
		const token = getItem<string>(this.state.key)
		const url = new URL(location.href)
		const hash = new URLSearchParams(location.hash.replace('#', '?'))
		if (url.searchParams.has('error') && url.searchParams.has('error_description')) {
			const errorMessage = url.searchParams.get('error') as string
			const description = url.searchParams.get('error_description') as string
			this.setState({
				error: { error: errorMessage, description },
				initializing: false
			})
			captureMessage(`${description}:${description}`, 'error')
		} else if (hash.has('access_token')) {
			this.state.client.parseHash(
				{ hash: location.hash, state: hash.get('state') ?? undefined },
				(err, authResult) => {
					if (err) console.error('parseHash', err)
					else if (authResult?.accessToken) this.getUserInfo(authResult.accessToken)
					this.setState({ initializing: false })
				}
			)
		} else if (url.searchParams.has('code')) {
			this.state.client.client.oauthToken(
				{
					code: url.searchParams.get('code'),
					state: url.searchParams.get('state'),
					redirectUri: location.href,
					grantType: 'authorization_code'
				},
				async (err, authResult) => {
					if (err) console.warn(err, authResult)
					else if (authResult?.accessToken) {
						this.getUserInfo(authResult.accessToken)
					}
					this.setState({ initializing: false })
				}
			)
		} else if (token) {
			await this.getUserInfo(token)
			this.setState({ initializing: false })
		} else {
			this.setState({ initializing: false })
		}
	}

	async componentDidUpdate(prevProps: unknown, prevState: Context) {
		const { accessToken, user, error } = this.state
		if (accessToken !== prevState.accessToken) {
			setItem(this.state.key, accessToken || '')
		}
		if (user !== prevState.user || error !== prevState.error) {
			this.setState({ loading: false })
		}
	}

	render() {
		return <Auth0Context.Provider value={this.state}>{this.props.children}</Auth0Context.Provider>
	}
}
