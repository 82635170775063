import type { CountryCode, Market } from '~/types'
import { makeActionCreator } from './actionCreator'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	updateCountry: makeActionCreator<CountryCode>()('COUNTRY_UPDATE'),
	setMarkets: makeActionCreator<Market[]>()('MARKETS_UPDATE'),

	setSelectorOpen: makeActionCreator<boolean>()('COUNTRY_SELECTOR_SET')
}
