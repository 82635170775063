import type { CheckoutResponse, FormValues } from '~/types'
import { BILLING_FORM_FIELDS, getBillingFormValues, getDefaultBillingFormValues } from './BillingForm.config'
import { getDefaultLoginFormValues, getLoginFormValues, LOGIN_FORM_FIELDS } from './LoginForm.config'
import { getDefaultPaymentFormValues, getPaymentFormValues, PAYMENT_FORM_FIELDS } from './PaymentForm.config'
import { ADDRESS_FORM_FIELDS, getDefaultShippingFormValues, getShippingFormValues } from './ShippingForm.config'

export type CheckoutFormValues = FormValues<typeof FORM_FIELDS>

export const FORM_FIELDS = {
	...LOGIN_FORM_FIELDS,
	...ADDRESS_FORM_FIELDS,
	...BILLING_FORM_FIELDS,
	...PAYMENT_FORM_FIELDS
} as const

export function getFormValuesFromCheckout(checkout: CheckoutResponse): Required<CheckoutFormValues> {
	return {
		...getLoginFormValues(checkout),
		...getShippingFormValues(checkout),
		...getBillingFormValues(checkout),
		...getPaymentFormValues(checkout)
	}
}

export function getDefaultCheckoutFormValues(): Required<CheckoutFormValues> {
	return {
		...getDefaultLoginFormValues(),
		...getDefaultShippingFormValues(),
		...getDefaultBillingFormValues(),
		...getDefaultPaymentFormValues()
	}
}
