import { Component, createContext, PropsWithChildren, useContext } from 'react'
import { ProductDocument } from '~/queries'
import type { GQL, Market } from '~/types'
import { gqlClient } from './graphql'

interface Context {
	product: GQL.ProductFragment | null
}

export const ProductContext = createContext<Context>({ product: null })

export function useProduct() {
	const context = useContext(ProductContext)
	return context.product
}

export interface ProductProviderProps extends PropsWithChildren, Context {
	market: Market | null
}

export class ProductProvider extends Component<ProductProviderProps> {
	state: Context = {
		product: this.props.product
	}

	async componentDidUpdate(prevProps: ProductProviderProps, _prevState: Context) {
		const { market } = this.props
		const { product } = this.state
		if (product && market && prevProps.market?._id !== market?._id) {
			const res = await gqlClient(ProductDocument, { productId: product._id, marketID: market._id })
			if (res.data) this.setState({ product: res.data })
		}
	}

	render() {
		return <ProductContext.Provider value={this.state}>{this.props.children}</ProductContext.Provider>
	}
}
