import type { PaymentMethodCreateParams } from '@stripe/stripe-js'
import { isPresent } from 'ts-extras'
import type { Checkout, CheckoutInput, Country, CountryCode, GQL, Nullable } from '~/types'
import { deepReplaceValues, except, toFullName } from '../helpers'
import { getDefaultBillingFormValues } from './BillingForm.config'
import type { CheckoutFormValues } from './Form.config'
import type { PaymentFormValues } from './PaymentForm.config'
import { getDefaultPaymentFormValues } from './PaymentForm.config'

export function toShippingInput(
	form: CheckoutFormValues | Checkout['properties'],
	country: Nullable<Country>
): GQL.ShippingInput {
	if ('tel-country-code' in form) {
		const dialCode = form['tel-country-code']
			? form['tel-country-code'].startsWith('+')
				? form['tel-country-code']
				: `+${form['tel-country-code']}`
			: country?.dialCode

		return {
			firstName: form['given-name'] || '',
			lastName: form['family-name'] || null,
			phone: [dialCode, form['tel-national']].filter(isPresent).join(''),
			email: form.email || null,
			address: {
				line1: form['address-line1'] || '',
				line2: form['address-line2'] || null,
				line3: form['address-line3'] || null,
				city: form['post-town'] || '',
				state: country?.code === 'US' ? form.state : null,
				postal_code: form['postal-code'] || '',
				country: (country?.code as CountryCode) || ''
			}
		}
	} else if ('shipping_email' in form) {
		return {
			firstName: form.shipping_first_name || '',
			lastName: form.shipping_last_name || '',
			phone: form.shipping_phone || '',
			email: form.shipping_email || '',
			address: {
				line1: form.shipping_line_1 || '',
				line2: form.shipping_line_2 || null,
				line3: form.shipping_line_3 || null,
				city: form.shipping_city || '',
				state: form.shipping_state || null,
				postal_code: form.shipping_postal_code || '',
				country: (form.shipping_country || '') as CountryCode
			}
		}
	} else {
		throw new Error('Cannot create ShippingInput, expected tel-country-code or shipping_email to be present')
	}
}

export function toBillingInput(
	form: CheckoutFormValues | Checkout['properties'],
	shipping: GQL.ShippingInput
): GQL.BillingInput {
	if ('purchase-role' in form) {
		const defaults = getDefaultBillingFormValues()
		const isCompany = (form['purchase-role'] || defaults['purchase-role']) === 'business'
		const businessDetails = isCompany
			? {
					name: form['billing-name'] || '',
					organization: form['billing-organization'] || '',
					email: form['billing-email'] || '',
					poNumber: form.ponumber || '',
					vatNumber: formatTaxNumber(form.vatnumber, shipping.address.country)
			  }
			: { name: '', organization: '', email: '', poNumber: '', vatNumber: '' }
		return {
			...businessDetails,
			isCompany,
			name: businessDetails.name || toFullName(shipping),
			organization: businessDetails.organization || '',
			email: (businessDetails.email || form.email || '')?.trim(),
			phone: shipping.phone || '',
			address: !form.separateBilling
				? shipping.address
				: {
						line1: form['billing-address-line1'] || '',
						line2: form['billing-address-line2'] || '',
						line3: '',
						city: form['billing-post-town'] || '',
						postal_code: form['billing-postal-code'] || '',
						state: shipping.address.country === 'US' ? form['billing-state'] || '' : '',
						country: shipping.address.country
				  }
		}
	} else if ('shipping_email' in form) {
		return {
			isCompany: !!form.billing_organization,
			organization: form.billing_organization || null,
			name: form.billing_name || toFullName(shipping) || '',
			email: form.billing_email || shipping.email || '',
			phone: form.billing_phone || form.billing_phone || '',
			poNumber: form.billing_po_number || null,
			vatNumber: form.billing_vat_number || null,
			address: form.billing_line_1
				? {
						line1: form.billing_line_1 || '',
						line2: form.billing_line_2 || null,
						line3: form.billing_line_3 || null,
						city: form.billing_city || '',
						postal_code: form.billing_postal_code || '',
						state: form.billing_state || null,
						country: (form.billing_country || '') as CountryCode
				  }
				: shipping.address
		}
	} else {
		throw new Error('Cannot create BillingInput, expected billing-name or billing_name to be present')
	}
}

export function toPaymentInput(form: PaymentFormValues | Checkout['properties']): GQL.PaymentInput {
	const defaults = getDefaultPaymentFormValues()
	if ('payment-method' in form) {
		return {
			coupons: [form.coupon].filter(isPresent),
			method: form['payment-method'] ?? defaults['payment-method'],
			savePaymentDetails: form.savePaymentDetails ?? defaults.savePaymentDetails
		}
	} else if ('payment_method' in form) {
		return {
			coupons: form.coupon_codes ?? [],
			method: form.payment_method ?? defaults['payment-method'],
			savePaymentDetails: false
		}
	} else {
		return {
			method: defaults['payment-method'],
			savePaymentDetails: defaults.savePaymentDetails
		}
	}
}

/**
 * Convert formvalues to input to be used in the checkout api
 * @note Make sure values that should be sent are set to '', not undefined so that they are properly cleared
 * @TODO Enforce this consistently. Should we use null values or empty strings to unset values?
 * @param formvalues
 * @returns
 */
export function toCheckoutInput(form: Checkout['properties']): CheckoutInput
export function toCheckoutInput(form: CheckoutFormValues, country: Country): CheckoutInput
export function toCheckoutInput(form: CheckoutFormValues | Checkout['properties'], country?: Country) {
	const shipping = toShippingInput(form, country)
	return {
		user: {
			email: ('shipping_email' in form ? form.shipping_email : 'email' in form ? form.email : '') || ''
		},
		shipping,
		billing: toBillingInput(form, shipping),
		payment: toPaymentInput(form)
	}
}

export function formatTaxNumber(taxNumber: Nullable<string>, country: Nullable<CountryCode>): string {
	if (!taxNumber) return ''
	let formatted: string
	switch (country) {
		case 'NO':
			formatted = taxNumber.endsWith('MVA') ? taxNumber : `${taxNumber}MVA`
			break

		default:
			formatted = taxNumber
			break
	}
	return formatted.replaceAll(' ', '')
}

export function toStripeDetails(
	values: CheckoutFormValues | Pick<CheckoutInput, 'billing' | 'shipping'>,
	country: Country
): PaymentMethodCreateParams.BillingDetails {
	const isFormValues = (v: typeof values): v is CheckoutFormValues => 'address-line1' in v

	const details = isFormValues(values) ? toCheckoutInput(values, country) : values
	const billing = deepReplaceValues(
		except(details.billing, 'vatNumber', 'poNumber', 'organization', 'isCompany'),
		null,
		''
	)

	return {
		...billing,
		email: billing.email || details.shipping.email || '',
		address: {
			country: billing.address?.country,
			line1: billing.address?.line1,
			line2: billing.address?.line2,
			postal_code: billing.address?.postal_code,
			city: billing.address?.city,
			state: billing.address?.state ?? billing.address?.city
		}
	}
}
