/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable max-len */

// TODO: C type parameters doesn't work with unions, making them all unknown
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type EmptyAction<T> = { type: T }
type EmptyActionCreator<T = never> = () => EmptyAction<T>
type ActionPayloadCreator<T, P = never> = (payload: P) => EmptyAction<T> & { payload: P }
type ActionMetaCreator<T, P, M = never> = (payload: P, meta: M) => EmptyAction<T> & { payload: P; meta: M }
type ActionMetaOnlyCreator<T, M = never> = (meta: M) => EmptyAction<T> & { meta: M }

type Never = null

// prettier-ignore
export const makeActionCreator = <P = Never, M = Never>() => <T extends string>(type: T): //
	{ type: T } &
	[M] extends [Never]
		? ([P] extends [Never]
			? EmptyActionCreator<T>
			: ActionPayloadCreator<T, P>)
		: ActionMetaCreator<T, P, M> =>
{
	const action = (payload?: P, meta?: M) => ({
		type,
		payload,
		meta,
	})
	action.type = type
	return action as any
}

// prettier-ignore
export const makeMetaActionCreator = <M>() => <T extends string>(type: T): EmptyAction<T> & ActionMetaOnlyCreator<T, M> => {
	const action = (meta: M) => ({
		type,
		meta,
	})

	action.type = type
	return action as any
}

export type GetMetaActions<T> = T extends { meta: any } ? T : never

export type FilterActions<TAction extends { type: string }, TKey extends string> = TAction extends {
	type: `${string}${TKey}${string}`
}
	? TAction
	: never
