export async function loadHLS(uri: string, elemenet: HTMLVideoElement) {
	// HLS.js size is large, so only import it if needed
	const Hls = (await import('hls.js')).default

	if (!Hls.isSupported()) {
		const player = new Hls()
		player.loadSource(uri)
		player.attachMedia(elemenet)
		return player
	} else {
		console.error('This is a legacy browser that doesnt support MSE')
		return null
	}
}
