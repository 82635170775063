/* eslint-disable @typescript-eslint/no-namespace */
import type { PaymentIntent } from '@stripe/stripe-js'
import type Stripe from 'stripe'
import { isPresent } from 'ts-extras'
import { SetOptional } from 'type-fest'
import type { CheckoutInput, CountryCode, GQL, Hubspot, Market, Nullable, StripeMetadata } from '~/types'
import { DEFAULT_TAX_NAME } from '../constants'
import { DeepReplaceValue, deepReplaceValues, except, toFirstAndLastName, toFullName } from '../helpers'
import { VatIDs } from './vatIdConst'
import { VatID } from './vatIds'

/**
 * Extracts the stripe id from a stripe object where the object is expandable
 * @param data The stripe object to extract from
 * @param key if the ID in the ojbect is not located on the id property, specify the key
 */
export function stripeId<T extends string | { id?: string }>(data: Nullable<T>): Nullable<string>
// eslint-disable-next-line @typescript-eslint/unified-signatures -- Incorrectly reports error, this scenario will fail if we remove this overload
export function stripeId<T extends string | Record<string, any>>(data: Nullable<T>, key: keyof T): Nullable<string>
export function stripeId<T extends string | Record<string, any>>(data: Nullable<T>, key?: keyof T): Nullable<string> {
	if (!data) return null
	if (typeof data === 'string') return data
	return key ? data[key] : data.id
}

export function createStripeTaxId(code: CountryCode, vatNumber: string) {
	const type = getStripeTaxType(code, vatNumber)
	switch (code) {
		case 'GB':
			return {
				type,
				value: vatNumber.startsWith(code) ? vatNumber : `${code}${vatNumber}`
			}

		case 'NO':
		case 'US':
		default:
			return {
				type,
				value: vatNumber
			}
	}
}

/**
 * Get stripe tax code based on market. TODO: Verify this for all markets
 * @param code
 * @returns
 */
export function getStripeTaxType(code: CountryCode, vatNumber: string): Stripe.TaxIdCreateParams.Type {
	switch (code) {
		case 'US':
			return VatID.US.us_ein
		case 'NO':
			return VatID.NO.no_vat
		case 'GB':
			return vatNumber.startsWith('GB') || vatNumber.match(/^\d/g) ? VatID.GB.gb_vat : VatID.GB.eu_vat

		default: {
			// Check if vatNumber starts with for instance 'PT' for Portugal. Is true if code is EU vat code
			const isEuVat = vatNumber.match(/^[A-Z]{2}/)
			if (isEuVat) return 'eu_vat'
			if (code in VatIDs) {
				const vatID = VatIDs[code as keyof typeof VatIDs]?.[0]
				if (vatID) return vatID
			}
			throw new Error(`Unsupported VAT ID for country "${code}"`)
		}
	}
}

export function getStripeInvoiceTaxType(code: CountryCode): Stripe.TaxRate.TaxType | undefined {
	switch (code) {
		case 'US':
			return 'sales_tax'
		default:
			return 'vat'
	}
}

export function toStripeShipping(details: GQL.ShippingInput): Stripe.PaymentIntentCreateParams.Shipping {
	return {
		name: toFullName(details),
		phone: details.phone,
		address: toStripeAddress(details.address)
	}
}

export function toStripeAddress(address: Nullable<GQL.AddressInput>): Stripe.AddressParam {
	return deepReplaceValues(except(address, 'line3'), null, undefined)
}

export function toPaymentMetadata({
	priceDetails,
	checkout,
	market
}: {
	priceDetails: SetOptional<
		GQL.PriceDetails,
		| 'amountDue'
		| 'currencyPosition'
		| 'displayCurrency'
		| 'invoicing'
		| 'remaining'
		| 'taxName'
		| 'thousandSeparator'
	>
	checkout: Pick<Hubspot.Deal, 'id' | 'properties'>
	market: Market
}): StripeMetadata & Stripe.Metadata {
	const payment = deepReplaceValues(priceDetails, null, '') as unknown as DeepReplaceValue<
		typeof priceDetails,
		number | null,
		string
	>
	const details = checkout.properties
	return {
		...payment,
		email: details.billing_email ?? '',
		name: details.billing_name ?? '',
		organization: details.billing_organization ?? '',
		phone: details.billing_phone ?? '',
		poNumber: details.billing_po_number ?? '',
		displayCurrency: priceDetails.displayCurrency ?? market.currencySymbol ?? '',
		vatNumber: details.billing_vat_number ?? '',
		hubspot_id: checkout.id,
		isCompany: details.billing_organization ? 'true' : 'false',
		marketID: market._id,
		amountDue: priceDetails.amountDue?.toFixed(2) ?? '',
		remaining: priceDetails.remaining?.toFixed(2) ?? '',
		invoicing: payment.invoicing || market.invoicingSolution,
		thousandSeparator: priceDetails.thousandSeparator ?? market.thousandSeparator ?? '',
		currencyPosition: priceDetails.currencyPosition ?? market.currencyPosition ?? '',
		taxName: priceDetails.taxName || market.vatName || DEFAULT_TAX_NAME,
		transactionID: priceDetails.transactionID ?? '',
		offer: priceDetails.offer ?? '',
		on_behalf_of: market.stripeId,
		cjevent: checkout.properties.cjevent ?? '',
		utm_campaign: checkout.properties.utm_campaign ?? '',
		utm_medium: checkout.properties.utm_medium ?? '',
		utm_source: checkout.properties.utm_source ?? ''
	}
}

function canParse(value: any): value is string {
	return isPresent(value) && value !== ''
}

export function toPriceDetails(data: Stripe.PaymentIntent | Stripe.Invoice): GQL.PriceDetails {
	if (!data.metadata) throw Error('No metadata present')
	const meta = data.metadata as unknown as StripeMetadata
	const compareAtPrice = canParse(meta.compareAtPrice) ? Number.parseFloat(meta.compareAtPrice) : null
	const amountDue = canParse(meta.amountDue) ? Number.parseFloat(meta.amountDue) : 0
	const depositAmount = canParse(meta.depositAmount) ? Number.parseFloat(meta.depositAmount) : null
	const depositPercentage = canParse(meta.depositPercentage) ? Number.parseFloat(meta.depositPercentage) : null
	const depositTax = canParse(meta.depositTax) ? Number.parseFloat(meta.depositTax) : null
	const subtotal = canParse(meta.subtotal) ? Number.parseFloat(meta.subtotal) : 0
	const total = canParse(meta.total) ? Number.parseFloat(meta.total) : 0
	const remaining = canParse(meta.remaining) ? Number.parseFloat(meta.remaining) : total
	const savings = canParse(meta.savings) ? Number.parseFloat(meta.savings) : 0
	const tax = canParse(meta.tax) ? Number.parseFloat(meta.tax) || 0 : null
	const taxPercent = canParse(meta.taxPercent) ? Number.parseFloat(meta.taxPercent) : null

	return {
		priceType: meta.priceType || 'deposit_initial',
		compareAtPrice,
		amountDue,
		depositAmount,
		depositPercentage,
		depositTax,
		subtotal,
		total,
		remaining: isNaN(remaining) ? total - amountDue : remaining,
		savings,
		tax,
		taxPercent,
		currency: meta.currency,
		displayCurrency: meta.displayCurrency || meta.currency,
		invoicing: 'stripe',
		taxBehavior: meta.taxBehavior,
		taxName: meta.taxName || DEFAULT_TAX_NAME,
		thousandSeparator: meta.thousandSeparator || '',
		currencyPosition: meta.currencyPosition,
		offer: meta.offer,
		transactionID: meta.transactionID
	}
}

/**
 * Converts an Invoice or Payment intent (usually received from webooks) back into the original checkout input
 * @param data
 * @returns
 */
export function toCheckoutInputFromStripe(data: Stripe.PaymentIntent | Stripe.Invoice): Required<CheckoutInput> {
	const metadata = data.metadata as unknown as StripeMetadata

	if (data.object === 'invoice') {
		const [firstName, lastName] = toFirstAndLastName(data.customer_name)
		return {
			user: {
				email: data.customer_email ?? ''
			},
			shipping: {
				firstName,
				lastName,
				email: data.customer_email,
				phone: data.customer_phone ?? '',
				address: toAddress(data.customer_address)
			},
			billing: {
				isCompany: metadata.isCompany === 'true',
				name: metadata.name,
				vatNumber: metadata.vatNumber,
				email: metadata.email,
				organization: metadata.organization,
				poNumber: metadata.poNumber,
				phone: metadata.phone ?? data.customer_phone,
				address: toAddress(data.customer_address)
			},
			payment: {
				method: 'invoice'
			}
		}
	} else if (data.object === 'payment_intent') {
		const [firstName, lastName] = toFirstAndLastName(data.shipping?.name)
		return {
			user: {
				email: data.receipt_email ?? ''
			},
			shipping: {
				firstName,
				lastName,
				email: data.receipt_email, // TODO ?
				phone: data.shipping?.phone ?? '',
				address: toAddress(data.shipping?.address)
			},
			billing: {
				isCompany: metadata.isCompany === 'true',
				name: metadata.name,
				vatNumber: metadata.vatNumber,
				email: data.receipt_email || metadata.email,
				organization: metadata.organization,
				poNumber: metadata.poNumber,
				phone: metadata.phone,
				address: toAddress(data.shipping?.address)
			},
			payment: {
				method: 'card'
			}
		}
	} else {
		throw new Error('Unknown type received')
	}
}

function toAddress(address: PaymentIntent.Shipping['address'] | null) {
	return {
		...deepReplaceValues(address, null, ''),
		country: address?.country as CountryCode,
		line3: ''
	}
}

/**
 * Make sure PO number always contains at least 1 letter
 * @param poNumber
 * @returns
 */
export function toStatementDescriptor(poNumber: Nullable<string>): string | undefined {
	const statement = poNumber ? 'Nomono'.slice(0, 21 - Math.min(poNumber.length, 21)) : 'Nomono'
	return [statement, poNumber].filter((s) => !!s).join(' ')
}

export function couponID(coupon: Stripe.Coupon, stripeAccount: Nullable<string>): string {
	return [stripeAccount, coupon.id].filter(Boolean).join('__')
}
