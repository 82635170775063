import { screenSizes } from '@nomonosound/gravity'
import { useQuery } from '@tanstack/react-query'
import { throttle } from 'lodash'
import {
	Dispatch,
	RefObject,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isPresent } from 'ts-extras'
import type {
	BlogPost,
	Country,
	CountryCode,
	ExtractRef,
	HubspotFormSettings,
	Market,
	Nullable,
	Page,
	SanityReference,
	State
} from '~/types'
import { getCountryInfo, getMarket } from './country'
import { GlobalContext } from './next'
import { Actions } from './redux'
import { getClient, usePreviewSubscription } from './sanity'
import { getItem, setItem } from './storage'

export function useSettings() {
	return useContext(GlobalContext)
}

export function useStorageState<S = undefined>(cacheKey: string, initialState?: S): [S, Dispatch<SetStateAction<S>>] {
	const storage = getItem<S>(cacheKey)
	const [state, setState] = useState(storage ?? initialState)
	useEffect(() => {
		setItem(cacheKey, state)
	}, [cacheKey, state])
	return [state as S, setState as Dispatch<SetStateAction<S>>]
}

/**
 * Returns {@link useEffect} if code is running on server, and {@link useLayoutEffect} if running on client
 */
export const useIsomorphicEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect

export function useWindowSize(throttleMs = 50) {
	const [width, setWidth] = useState(0)
	const [bodyWidth, setBodyWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [isMobile, setIsMobile] = useState<boolean>(false)

	useIsomorphicEffect(() => {
		const handleResize = throttle(() => {
			setWidth(window.innerWidth)
			setBodyWidth(window.document.body.clientWidth) // skipping scrollbar width
			setHeight(window.innerHeight)
			setIsMobile(window.matchMedia(`(max-width: ${screenSizes.small})`).matches)
		}, throttleMs)

		handleResize()

		window.addEventListener('resize', handleResize, { passive: true, capture: true })
		return () => window.removeEventListener('resize', handleResize)
	}, [throttleMs])

	return {
		width,
		bodyWidth,
		height,
		isMobile
	}
}

export function useMarkets(): Market[] {
	const globalContext = useSettings()
	const context = useSelector((state: State) => state.market)
	return context.markets.length ? context.markets : globalContext.markets
}

export function useCountries(): Country[] {
	const markets = useMarkets()
	return useMemo(
		() => markets.flatMap((m) => m.countries?.map((c) => getCountryInfo({ country: c }))).filter(isPresent),
		[markets]
	)
}

export function useSanityRef<T extends SanityReference<any>>(ref: Nullable<T>) {
	const result = usePreviewSubscription<ExtractRef<T>>(`coalesce(*[_id == 'drafts.' + $id][0], *[_id == $id][0])`, {
		params: { id: ref?._ref },
		initialData: ref && '_ref' in ref ? null : (ref as any),
		enabled: ref && '_ref' in ref
		// dataset: TODO
	})
	if (!ref) return { data: null, isLoading: false, error: 'No reference provided' }
	return result
}

export function useMarket(code?: CountryCode): Market | null {
	const market = useSelector((state: State) => (code ? getMarket(state.market.markets, code) : state.market?.current))
	return market ?? null
}

export function useCountry() {
	const country = useSelector((state: State) => state.market.country)
	const dispatch = useDispatch()
	const setCountry = useCallback((code: CountryCode) => dispatch(Actions.updateCountry(code)), [dispatch])
	return [country, setCountry] as const
}

export function useHubspotForm(guid?: string) {
	const response = useQuery<HubspotFormSettings>(
		['hubspot-forms', guid],
		async () => {
			const formResponse = await fetch(`/api/hubspot/form?guid=${guid}`)
			return formResponse.json()
		},
		{
			enabled: !!guid,
			staleTime: Infinity
		}
	)

	return { form: response.data, isLoading: response.isLoading, error: response.error }
}

export function usePage<T extends Pick<Page | BlogPost, '_id' | '_type' | 'slug'>>(
	page: Nullable<T | SanityReference<T>>
) {
	const res = useQuery(
		[page && '_ref' in page ? page._ref : page?._id],
		() =>
			getClient().fetch<ExtractRef<T>>(/* groq */ `*[_id == $id] { slug, meta, title, category-> }[0]`, {
				id: page && '_ref' in page ? page._ref : page?._id
			}),
		{ enabled: page?._type === 'reference', initialData: page as ExtractRef<typeof page> }
	)
	return res.data
}

export function useOnScreen(ref: RefObject<HTMLElement>) {
	const observerRef = useRef<IntersectionObserver | null>(null)
	const [isOnScreen, setIsOnScreen] = useState(false)

	useEffect(() => {
		observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting), {
			rootMargin: '10px 10px 10px 10px'
		})
	}, [])

	useEffect(() => {
		if (!ref.current) {
			return
		}

		observerRef.current?.observe(ref.current)

		return () => {
			observerRef.current?.disconnect()
		}
	}, [ref])

	return isOnScreen
}
