/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { QueryClientProvider } from '@tanstack/react-query'
import App, { AppProps } from 'next/app'
import Head from 'next/head'
import { Provider } from 'react-redux'
import { isPresent } from 'ts-extras'
import type { Merge, SetOptional } from 'type-fest'
import { ConsentManager } from '~/components/layout/ConsentManager'
import { globalSettingsQuery } from '~/queries'
import type { Dataset, IGlobalContext, Market, SiteSettings } from '~/types'
import {
	AuthProvider,
	GlobalContext,
	filterDataToSingleItem,
	getClient,
	queryClient,
	useIsomorphicEffect,
	usePreviewSubscription
} from '~/utils'
import { getCookie, setCookie } from '~/utils/cookie'
import { storeWrapper } from '~/utils/redux/configureStore'

import '../styles/globals.css'

export interface NomonoAppProps extends AppProps {
	globalProps: Merge<IGlobalContext, { siteSettings: SiteSettings[]; markets: Market[] }>
	dataset: Dataset
	isPreview: boolean
}

const getFaviconTheme = () => {
	switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
		case 'production':
			return { prefix: '/favicons/light', color: '#f7f5f2' }
		case 'preview':
			return { prefix: '/favicons/staging', color: '#1f1f21' }
		case 'development':
		case undefined:
			return { prefix: '/favicons/dev', color: '#ffe46c' }
	}
}

function MyApp({ Component, ...rest }: NomonoAppProps) {
	const { store, props: wrappedProps } = storeWrapper.useWrappedStore(rest)
	const props = wrappedProps as typeof rest
	const globalData = usePreviewSubscription(globalSettingsQuery, {
		initialData: props.globalProps,
		enabled: props.router.isPreview
	})
	const { siteSettings, plans, markets, checkoutSettings, kbSettings } = globalData.data
	const globalContext: IGlobalContext = {
		siteSettings: filterDataToSingleItem(siteSettings ?? props.globalProps.siteSettings)!,
		checkoutSettings: filterDataToSingleItem(checkoutSettings ?? props.globalProps.checkoutSettings)!,
		kbSettings: filterDataToSingleItem(kbSettings ?? props.globalProps.kbSettings)!,
		legalSettings: filterDataToSingleItem(props.globalProps.legalSettings)!,
		markets,
		plans
	}
	const favIcon = getFaviconTheme()

	useIsomorphicEffect(() => {
		// if a site wide discount is configured in Sanity, apply that and ignore other discounts
		if (globalContext.siteSettings.sanityDiscountCouponId) {
			setCookie('ref', globalContext.siteSettings.sanityDiscountCouponId, {
				expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7)
			})
			return
		}

		const ref = props.router.query.ref
		if (!isPresent(ref)) return
		setCookie('ref', Array.isArray(ref) ? ref[0] : ref, { expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) })
	})

	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
				<link rel="apple-touch-icon" sizes="180x180" href={`${favIcon.prefix}/apple-touch-icon.png`} />
				<link rel="icon" href={`${favIcon.prefix}/favicon.ico`} />
				<link rel="icon" type="image/png" sizes="32x32" href={`${favIcon.prefix}/favicon-32x32.png`} />
				<link rel="icon" type="image/png" sizes="16x16" href={`${favIcon.prefix}/favicon-16x16.png`} />
				<link rel="manifest" href={`${favIcon.prefix}/site.webmanifest`} />
				<meta name="msapplication-config" content={`${favIcon.prefix}/browserconfig.xml`} />
				<meta name="msapplication-TileColor" content={favIcon.color} />
				<meta name="theme-color" content={favIcon.color} />
				<meta name="app-version" content={process.env.NEXT_PUBLIC_PACKAGE_VERSION} />
			</Head>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<GlobalContext.Provider value={globalContext}>
						<AuthProvider>
							<Component {...props.pageProps} />
						</AuthProvider>
						<ConsentManager />
					</GlobalContext.Provider>{' '}
				</QueryClientProvider>
			</Provider>
		</>
	)
}

type InitialProps = SetOptional<NomonoAppProps, 'router' | 'Component'>

MyApp.getInitialProps = storeWrapper.getInitialAppProps((_store) => async (context): Promise<InitialProps> => {
	const dataset = getCookie('dataset', context.ctx)
	const client = getClient(context.router.isPreview, dataset ? { dataset } : undefined)
	const [globalProps, pageProps] = await Promise.all([
		client.fetch<NomonoAppProps['globalProps']>(globalSettingsQuery),
		App.getInitialProps(context)
	])
	return {
		pageProps,
		globalProps: { ...globalProps },
		dataset: client.config().dataset as Dataset,
		isPreview: !!client.config().token
	}
})

export default MyApp
