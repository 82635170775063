import { isPresent } from 'ts-extras'
import type { CountryCode, Market, Nullable } from '~/types'
import { default as countries } from './countries.json'
import { getCountryInfo } from './getCountryInfo'
export * from './getCountryInfo'
export * from './states'

export const COUNTRIES = countries.map((c) => getCountryInfo({ country: c.cca2 })).filter(isPresent)

export function getMarket(markets: Market[], country: Nullable<CountryCode>): Market | null {
	if (!country) return null
	return markets.find((m) => m.countries.includes(country)) ?? null
}
