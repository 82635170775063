import type { TypedDocumentNode as Document } from '@graphql-typed-document-node/core'
import { captureException } from '@sentry/nextjs'
import type { QueryKey, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { GQL } from '~/types'
import { useAuth0 } from '../AuthProvider'
import { except } from '../helpers'
import { gqlClient } from './gqlClient'
import { cacheKey } from './reactQuery'

export interface QueryOptions<T = unknown, K extends QueryKey = []>
	extends UseQueryOptions<GQL.Response<T>, GQL.Response<T>['errors'], GQL.Response<T>, K> {
	/** Don't refetch when these variables changes */
	cacheIgnore?: K
	enabled?: boolean | undefined
}

const defaults: Readonly<QueryOptions> = {
	refetchOnWindowFocus: false
}

/**
 * Wrapper around react-query's {@link useQuery}
 * @param query
 * @param variables
 * @returns
 */
export function useGQLQuery<T, V extends Record<string, any>, K extends keyof V>(
	query: Document<T, V>,
	variables?: V,
	options: QueryOptions<T, K[]> = {}
) {
	const { isAuthenticated, accessToken } = useAuth0()
	const queryKey = cacheKey(query)

	const { error, ...response } = useQuery(
		[queryKey, except(variables, ...(options.cacheIgnore ?? []))],
		async () => {
			const token = isAuthenticated ? accessToken : null
			const headers = token ? { Authorization: `Bearer ${token}` } : undefined
			return gqlClient(query, variables as V, headers)
		},
		{ ...defaults, ...options } as any
	)
	if (error) console.warn(queryKey, error)
	const errors = response.data?.errors ?? (error ? [error as GQL.ResponseError] : null)
	if (errors)
		captureException(new Error(errors[0].message || errors[0].stack?.split('\n')[0] || queryKey.toString()), {
			extra: { query: queryKey, ...errors },
			tags: errors.reduce((a, b, i) => ({ ...a, [`error.${i}`]: b.message }), {} as Record<string, string>)
		})
	return {
		...response,
		errors: errors?.length ? errors : null,
		data: response.data?.data
	}
}

/**
 * Wrapper around react-query's {@link useMutation}
 * @param query
 * @param variables
 * @returns
 */
export function useGQLMutation<T, V extends Record<string, any>, K extends keyof V>(
	query: Document<T, V>,
	options: QueryOptions<T, K[]> = {}
) {
	const { isAuthenticated, accessToken } = useAuth0()
	const queryKey = cacheKey(query)

	const { error, ...response } = useMutation(
		[queryKey, options],
		async (variables: V) => {
			const token = isAuthenticated ? accessToken : null
			const headers = token ? { Authorization: `Bearer ${token}` } : undefined
			return gqlClient(query, variables as V, headers)
		},
		{ ...defaults, ...options } as any
	)
	if (error) console.warn(queryKey, error)
	const errors = response.data?.errors ?? (error ? [error as GQL.ResponseError] : null)
	if (errors)
		captureException(new Error(errors[0].message || errors[0].stack?.split('\n')[0] || queryKey.toString()), {
			extra: { query: queryKey, ...errors },
			tags: errors.reduce((a, b, i) => ({ ...a, [`error.${i}`]: b.message }), {} as Record<string, string>)
		})
	return {
		...response,
		errors: errors?.length ? errors : null,
		data: response.data?.data
	}
}
