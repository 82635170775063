import { contentQuery } from './contentQuery'
import { linkFragment } from './sanityFragments'

export const checkoutSettingsQuery = /* groq */ `
	*[_type == "checkoutSettings"] {
		...,
		headerItems[] {
			...,
			${linkFragment},
			menus[]->{
				...,
				items[] {
					...,
					${linkFragment}
				}
			}
		},
		${contentQuery},
		bannerItems[] {
			...,
			${linkFragment},
			menus[]->{
				...,
				items[] {
					...,
					${linkFragment}
				}
			}
		},
		successPage {
			...,
			pageReference->{
				_id,
				_type,
				title,
				slug,
				meta,
				category->
			}
		}
	}
`
