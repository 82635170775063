import type { CheckoutResponse, FormValues } from '~/types'
import { US_STATES } from '~/utils/country'
import { createFormField } from './FormUtils'

export type ShippingFormValues = FormValues<typeof ADDRESS_FORM_FIELDS>

export const ADDRESS_FORM_FIELDS = {
	...createFormField({ name: 'given-name', label: 'First Name', required: true }),
	...createFormField({ name: 'family-name', label: 'Last Name' }),
	...createFormField({ name: 'address-line1', label: 'Street address', placeholder: 'Sound Street', required: true }),
	...createFormField({ name: 'address-line2' }),
	...createFormField({
		name: 'address-line3',
		placeholder: 'Company Name (optional)',
		autoComplete: 'organization'
	}),
	...createFormField({
		name: 'postal-code',
		label: 'Postal Code',
		placeholder: '10206',
		required: true
	}),
	...createFormField({
		name: 'post-town',
		label: 'City',
		autoComplete: 'address-level2',
		required: true
	}),
	...createFormField({
		name: 'state',
		label: 'State',
		autoComplete: 'address-level1',
		options: US_STATES.map((s) => ({ label: s.name, value: s.code })),
		required: true
	}),
	...createFormField({
		name: 'tel-country-code',
		label: 'Country Code',
		placeholder: '+1',
		type: 'tel'
	}),
	...createFormField({
		name: 'tel-national',
		type: 'tel',
		label: 'Phone number',
		placeholder: '(012)-345 6789',
		required: true
	})
} as const

export function getShippingFormValues(checkout: CheckoutResponse): Required<ShippingFormValues> {
	const properties = checkout.properties
	return {
		'given-name': properties.shipping_first_name ?? '',
		'family-name': properties.shipping_last_name ?? '',
		'address-line1': properties.shipping_line_1 ?? '',
		'address-line2': properties.shipping_line_2 ?? '',
		'address-line3': properties.shipping_line_3 ?? '',
		'postal-code': properties.shipping_postal_code ?? '',
		'tel-country-code': '', // TODO: Figure out a way to split phone numbers
		'tel-national': '',
		'post-town': properties.shipping_city ?? '',
		'state': properties.shipping_state ?? ''
	}
}

export function getDefaultShippingFormValues(): Required<ShippingFormValues> {
	return {
		'given-name': '',
		'family-name': '',
		'address-line1': '',
		'address-line2': '',
		'address-line3': '',
		'postal-code': '',
		'tel-country-code': '',
		'tel-national': '',
		'post-town': '',
		'state': ''
	}
}
