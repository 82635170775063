import type { DefaultPageProps } from '~/components/page'
import { contentQuery } from './contentQuery'
import { linkFragment } from './sanityFragments'

export const pageTypeFilter = /* groq */ `(
	_type == "legalPage" ||
	_type == "contactPage" ||
	_type == "frontPage" ||
	_type == "kbArticle"
)`

export const pageQuery = (filter: DefaultPageProps['pageFilter']) =>
	/* groq */ `*[(${pageTypeFilter}) && (${filter})] ${pageFragment}`

export const pageFragment = /* groq */ `
{
	...,
	category->,

	contentBlocks[] {
		...,
		${contentQuery},
		media[] {
			...,
			asset->
		},
		mainImage {
			asset->
		},
		mainImageMobile {
			asset->
		},
		heroImage {
			...,
			asset->
		},
		product->,
		points[] {
			...,
			link {
				...,
				${linkFragment}
			}
		},

		_type == 'customSection' => {
			faq->{
				...,
				questions[]->
			}
		},

		_type == 'scrollableVideo' => {
			...,
			video[] {
				...,
				asset->
			}
		}
	},
	_type == 'contactPage' => {
		topics[] {
			...,
			faq->{
				...,
				questions[]->
			},
		},
		faq->{
			...,
			questions[]->
		},
	},

	${contentQuery},
	heroImg {
		...,
		asset->
	}
}
`
