import type { SanityAsset } from '@sanity/image-url/lib/types/types'
import { startCase } from 'lodash'
import { isPresent } from 'ts-extras'
import type {
	BlogPost,
	ExtractRef,
	MarketItem,
	Nullable,
	Page,
	PageCategory,
	PageMeta,
	SanityDocument,
	SanityImageAsset,
	SanityLink,
	SanityRef
} from '~/types'
import { IS_NEXT_PREVIEW } from '../constants'

/**
 * Helper function to return the correct version of the document
 * If we're in "preview mode" and have multiple documents, return the draft
 */
export function filterDataToSingleItem<T extends SanityDocument>(
	data: T | T[],
	preview: boolean | null = IS_NEXT_PREVIEW()
): T | undefined {
	if (!Array.isArray(data)) {
		return data
	}

	if (data.length === 1) {
		return data[0]
	}

	if (preview) {
		return data.find((item) => item._id.startsWith(`drafts.`)) || data[0]
	}

	return data[0]
}

/**
 * Check if referenced asset is resolved
 */
export function isResolved<T extends Pick<SanityImageAsset, 'url'>>(
	reference: T | SanityAsset | undefined | null
): reference is T {
	if (reference === null || reference === undefined) return false
	if ('_id' in reference) return true
	if ('url' in reference) return true
	else return false
}

export function resolve<T extends SanityRef | { _id: string }>(ref: Nullable<T>): Nullable<ExtractRef<T>> {
	return ref as Nullable<ExtractRef<T>>
}

/**
 * Gets the url for a link object
 * @param link
 * @returns
 */
export function getLinkPath(link: Pick<SanityLink, 'externalUrl' | 'pageReference' | 'staticPath' | 'query'>) {
	const pageRef = resolve(link.pageReference)
	const url = link.staticPath ?? link.externalUrl ?? getPageSlug(pageRef).path
	if (!url) return null
	const path = url.startsWith('#') || url.startsWith('/') || url.includes('://') ? url : `/${url}`
	return (path.length > 1 && path.endsWith('/') ? path.slice(0, -1) : path) + (link.query ?? '')
}

/**
 * Gets the correct text to display for a link object
 * @param link
 * @returns
 */
export function getLinkText(link: Pick<SanityLink, 'pageReference' | 'text'>): string | undefined {
	const page = resolve(link.pageReference)
	if (!page || link.text) return link.text
	const pageTitle = 'title' in page ? page.title : undefined
	const category = 'category' in page ? resolve(page.category) : undefined
	const categorySlugTitle = category?._type === 'pageCategory' ? startCase(category?.slug?.current) : undefined
	const metaTitle = 'meta' in page ? page.meta?.title : undefined
	return pageTitle || metaTitle || startCase(page.slug?.current) || category?.title || categorySlugTitle
}

export function getPageMeta(page: Page | BlogPost | null): PageMeta {
	if (!page) {
		return {
			canonical: null,
			updatedAt: null
		}
	}
	const updatedAt = new Date(page.meta?.updatedAtOverride || page._updatedAt || page._createdAt)
	const canonical = getPageSlug(page).path
	switch (page?._type) {
		case 'contactPage':
		case 'frontPage':
		case 'kbArticle':
		case 'legalPage':
			return {
				title:
					page.meta?.title ??
					('title' in page ? page.title : null) ??
					(page._type !== 'contactPage' ? startCase(page.slug?.current) || 'Nomono.co' : 'Contact Us'),
				description: page.meta?.description,
				image: page.meta?.image,
				canonical,
				updatedAt
			}

		case 'blogPost':
			return {
				title: page.meta?.title ?? page.title,
				description: page.meta?.description ?? page.shortText,
				image: page.meta?.image ?? page.mainImage,
				canonical,
				updatedAt
			}

		default: // TODO: Figure out why this can sometimes be called with empty type
			return {
				canonical: null,
				updatedAt
			}
	}
}

export function getPageSlug(page: Nullable<Page | BlogPost>) {
	const category = page && 'category' in page ? resolve(page.category) : undefined
	const categoryPath = category
		? category._type === 'pageCategory'
			? category.slug?.current
			: `${category._type}s`
		: undefined

	const path = [page?._type === 'blogPost' ? 'updates' : null, categoryPath, page?.slug?.current || null]
		.filter(isPresent)
		.join('/')
		.replace('//', '/')
	return {
		category: categoryPath ?? null,
		slug: page?.slug?.current ?? null,
		path
	}
}

export function getTaxMode(item: MarketItem, isCompany: boolean) {
	if (isCompany) {
		return item.productMarket.b2bTaxCalculation ?? item.market.taxCalculation
	} else {
		return item.productMarket.b2cTaxCalculation ?? item.market.taxCalculation
	}
}

export function categoryTitle(category: Nullable<PageCategory>) {
	return category?.title ?? startCase(category?.slug.current)
}
