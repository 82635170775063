import { call, put, select, takeLatest } from 'typed-redux-saga'
import { ProductsDocument } from '~/queries'
import { LineItem, Market, SegmentEvent, State } from '~/types'
import { getMarket } from '~/utils/country'
import { gqlClient } from '~/utils/graphql'
import { toAnalyticsProduct, track } from '../../track'
import { Action, Actions, ActionTypes } from '../actions'
import type { CartState } from '../reducers/cart'

export type CartSaveState = CartState['lineItems']

export function* cartSagas() {
	yield* takeLatest<ActionTypes, any>('CART_ADD', addToCart)
	yield* takeLatest<ActionTypes, any>('COUNTRY_UPDATE', updateLineItems)
}

// eslint-disable-next-line require-yield
function* addToCart(action: Action<'CART_ADD'>) {
	yield* call(() =>
		track(SegmentEvent.ProductAdded, {
			...toAnalyticsProduct(action.payload.product),
			quantity: action.payload.quantity
		})
	)
	if (action.meta === true) {
		location.pathname = '/checkout'
	}
}

async function reloadProducts(lineItems: LineItem[], market: Market) {
	const products = await gqlClient(ProductsDocument, {
		productIds: lineItems.map((li) => li.id),
		marketID: market._id
	})
	if (products.errors) return []
	else return products.data ?? []
}

function* updateLineItems(action: Action<'COUNTRY_UPDATE'>) {
	const lineItems = yield* select((s: State) => s.cart.lineItems)
	const market = yield* select((s: State) => getMarket(s.market.markets, action.payload))
	if (!market) {
		console.warn('No market selected')
		return
	}
	if (lineItems.length === 0) return
	const products = yield* call(reloadProducts, lineItems, market)
	for (const product of products) {
		yield* put(Actions.updateCartItem(product, product._id))
	}
}
