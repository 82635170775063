import { isPresent } from 'ts-extras'
import type { Block, DefaultPortableText } from '~/types'

export function toPlainText(blocks: DefaultPortableText) {
	try {
		return (
			blocks
				// loop through each block
				.map((data) => {
					if (!data) return null
					// if it's not a text block with children, return nothing
					// TODO: Expand to support more block types
					if (data._type !== 'block' || !data.children) {
						return null
					}
					const block = data as unknown as Block
					// loop through the children spans, and join the
					// text strings
					return block.children.map((child) => child.text).join('')
				})
				.filter(isPresent)
				.join('\n\n')
		)
	} catch (_) {
		return ''
	}
}
