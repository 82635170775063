import type { GQL, Step, WithValidation } from '~/types'
import { DEFAULT_PAYMENT_METHOD } from '../../checkout'
import { isValidFormDetails } from '../../form'
import type { Action } from '../actions'

export interface CheckoutDetails {
	user: WithValidation<{ email: string }>
	shipping: WithValidation<GQL.ShippingInput>
	billing: WithValidation<GQL.BillingInput>
	payment: GQL.PaymentInput
}

export interface CheckoutState {
	state: 'unloaded' | 'loading' | 'loaded'
	step: Step
	completedSteps: number
	id: string | null
	details: CheckoutDetails
	result: WithValidation<GQL.CheckoutResponse>
}

const initialState: CheckoutState = {
	state: 'unloaded',
	step: 1,
	id: null,
	completedSteps: 0,
	details: {
		user: null,
		shipping: null,
		billing: null,
		payment: {
			method: DEFAULT_PAYMENT_METHOD,
			savePaymentDetails: false
		}
	},
	result: null
}

export function checkout(state: CheckoutState = initialState, action: Action): CheckoutState {
	switch (action.type) {
		case 'CHECKOUT_ID_UPDATE':
			return {
				...state,
				id: action.payload
			}

		case 'CHECKOUT_FORMFIELD_UPDATE':
			return {
				...state,
				details: {
					...state.details,
					[action.meta]: action.payload
				}
			}

		case 'CHECKOUT_STEP_UPDATE':
			return {
				...state,
				step: action.payload
			}

		case 'CHECKOUT_VALIDATE_USER':
			return {
				...state,
				details: {
					...state.details,
					user: 'loading'
				}
			}
		case 'CHECKOUT_VALIDATE_SHIPPING':
			return {
				...state,
				details: {
					...state.details,
					shipping: 'loading'
				}
			}

		case 'CHECKOUT_VALIDATE_BILLING':
			return {
				...state,
				details: {
					...state.details,
					billing: 'loading'
				}
			}

		case 'CHECKOUT_VALIDATE_RESULT':
			return {
				...state,
				completedSteps: isValidFormDetails(action.payload)
					? Math.max(state.completedSteps, getStepNumber(action.meta))
					: state.completedSteps,
				details: {
					...state.details,
					[action.meta]: action.payload
				}
			}

		case 'CHECKOUT_SET_PAYMENT_METHOD':
			return {
				...state,
				details: { ...state.details, payment: { ...state.details.payment, method: action.payload } }
			}
	}

	return state
}

function getStepNumber(type: keyof CheckoutState['details']): Step {
	switch (type) {
		case 'user':
			return 1
		case 'shipping':
			return 2
		case 'billing':
			return 3
		case 'payment':
			return 4
	}
}
