import type { NextRequest } from 'next/server'
import type { Country, CountryCode } from '../../types/country'
import COUNTRIES from './countries.json'

export function getCountryInfo(geo: NextRequest['geo']): Country | null {
	const country = COUNTRIES.find((x) => x.cca2 === geo?.country || x.name === geo?.country)
	if (!country) return null

	return {
		name: country.name as string,
		code: country.cca2 as CountryCode,
		city: geo?.city ?? null,
		region: geo?.region ?? null,
		continent: country.continent ?? null,
		flag: country.flag,
		dialCode: country.dial_code
	}
}
