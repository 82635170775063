import { isObject, mapValues, omit, startCase, transform } from 'lodash'
import type { Primitive, SetOptional, Simplify } from 'type-fest'
import type { GQL, Nullable } from '~/types'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export type DeepReplaceValue<T extends Record<string, any>, TOld extends Primitive, TNew extends Primitive> = {
	[K in keyof T]: Exclude<T[K], null | undefined> extends Record<string, any>
		? Simplify<DeepReplaceValue<T[K], TOld, TNew>>
		: T[K] extends TOld
		? TNew
		: TOld extends T[K]
		? Exclude<T[K], TOld> | TNew
		: T[K]
}

/**
 * Recursively replaces values in a record matching oldValue with newValue.
 * @param obj
 * @param oldValue
 * @param newValue
 * @returns
 */
export function deepReplaceValues<T extends Record<string, any>, TOld extends Primitive, TNew extends Primitive>(
	obj: Nullable<T>,
	oldValue: TOld,
	newValue: TNew
): Simplify<DeepReplaceValue<T, TOld, TNew>> {
	return mapValues(obj, (value) =>
		isObject(value) ? deepReplaceValues(value, oldValue, newValue) : value === oldValue ? newValue : value
	) as DeepReplaceValue<T, TOld, TNew>
}

export function isText(val: any): val is number | string {
	return typeof val === 'number' || typeof val === 'string'
}

/**
 * Flatten an object with nested objects
 * @param obj Object to be flattened
 * @param prefix Optionally prefix object keys with a string
 * @returns
 */
export function normalize(
	obj: Record<string, any>,
	prefix: string | ((key: string) => string) = ''
): Record<string, Primitive> {
	return transform(
		obj,
		(result, value, key) =>
			Object.assign(
				result,
				typeof value !== 'object'
					? { [typeof prefix === 'string' ? prefix + key : prefix(key)]: value }
					: normalize(value, prefix)
			),
		{} as Record<string, Primitive>
	)
}

/** Like lodash´s {@link omit}, but only accepts keys that present in the object */
export function except<T extends object, K extends keyof T>(obj: Nullable<T>, ...paths: K[]): Omit<T, K> {
	if (!obj) return {} as Omit<T, K>
	return omit(obj, paths)
}

export function toFirstAndLastName(name: Nullable<string>): [string, string] {
	const [firstName, ..._lastName] = name?.split(' ') ?? ['', '', '']
	const lastName = _lastName.join(' ').trim()
	return [firstName, lastName]
}

export type NameDetails = SetOptional<Pick<GQL.ShippingInput, 'firstName' | 'lastName'>, 'lastName'>

export function toFullName(firstName: string, lastLame?: string): string
export function toFullName(details: NameDetails): string
export function toFullName(details: string | NameDetails, lastName?: string): string {
	if (typeof details === 'string') {
		return startCase(`${details} ${lastName}`).trim()
	} else if (details.lastName) {
		return startCase(`${details.firstName} ${details.lastName}`).trim()
	} else {
		return startCase(details.firstName).trim()
	}
}

/**
 * Like {@link Array.includes}, but array only accepts values that are valid values for @param value
 * @param array
 * @param value
 * @returns
 */
export function includes<T extends string, K extends T[]>(array: K, value: T): boolean {
	return array.includes(value)
}
