import { startCase } from 'lodash'
import type { SetRequired } from 'type-fest'
import type { AutoComplete, AutoCompleteField, Field, FormValues } from '~/types'

/**
 * Creates a formField object. Use with {@link FormValues} and {@link Field}
 * @param field A form field object with similar props as a regular input field would use.
 * 	If `name` resolves to a valid {@link AutoComplete} attribute, then autoComplete will automatically be set.
 * @returns props for
 * @example
 * const fields = {
 *		...createFormField({ name: 'name' }),
 *		...createFormField({
 *			type: 'email',
 *			name: 'email',
 *			label: 'Email address',
 *		}),
 * } as const
 *
 * const Form: React.FC<> = () => {
 *  const [form, setFormValues] = useState<FormValues<typeof fields>>({})
 * 	return (
 * 		<form>
 * 			<Input value={form['name']} {...fields['name']} onChange={onChange} />
 * 			<Input value={form['email']} {...fields['email']} onChange={onChange} />
 * 		</form>
 * 	)
 * }
 */
export function createFormField<T extends AutoCompleteField<K> | Field<K>, K extends string>(
	field: T extends AutoCompleteField ? T : SetRequired<T, 'name' | 'autoComplete'>
): { [R in T['name']]: Field<T['name']> & T } {
	return {
		[field.name as T['name']]: {
			...field,
			type: field.type ?? 'text',
			autoComplete: field.autoComplete || (field.name as T['name']),
			placeholder: field.placeholder ?? startCase(field.name)
		}
	} as any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Prevent type imports from getting pruned, they're used by docstrings
type _unused = FormValues<any> | AutoComplete
