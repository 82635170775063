import type { LineItem } from '~/types'
import { makeActionCreator, makeMetaActionCreator } from './actionCreator'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	/** Pass in line item and whether to to go to checkout or not (defaults to only adding to cart) */
	addToCart: makeActionCreator<LineItem, boolean | undefined>()('CART_ADD'),
	updateQuantity: makeActionCreator<number, LineItem['id']>()('CART_QUANTITY_UPDATE'),
	removeFromCart: makeMetaActionCreator<LineItem['id']>()('CART_REMOVE'),
	updateCartItem: makeActionCreator<LineItem['product'], LineItem['id']>()('CART_ITEM_UPDATE')
}
