export type PipeLineType = (typeof PipeLine)[keyof typeof PipeLine]

export const PipeLine = {
	Checkout: process.env.HUBSPOT_PIPELINE,
	Sales: process.env.HUBSPOT_SALES_PIPELINE
} as const

export const SalesStatus = {
	IdentifiedCase: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'appointmentscheduled' : '134428884',
	OfferRequested: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '141855477' : '134428885',
	OfferSent: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '31232227' : '134428886',
	OfferAccepted: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'closedwon' : '134428887',
	RequiresReview: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '141855474' : '136422846',
	ReadyForDraft: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '141855475' : '134428888',
	ReadyForSending: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '141855476' : '134522337'
} as const

/** TODO: Convert to enum when support for conditional enums is released with Typescript 5 */
export const OrderStatus = {
	CheckoutAbandoned: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232368' : '85615551',
	CheckoutStarted: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232369' : '85615552',
	PaymentPending: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232370' : '85615553',
	InvoiceExpired: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '134517744' : '',
	DepositPaid: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232371' : '85615554',
	FinalInvoiceSent: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232372' : '85615555',
	OnHold: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '237043154' : '',
	FullAmountPaid: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232373' : '85615556',
	Shipped: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '198175438' : '198059462',
	Delivered: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '199086051' : '85615557',
	Returned: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '117232575' : '85600462',
	Cancelled: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '139277296' : ''
} as const

export type OrderStatusType = (typeof OrderStatus)[keyof typeof OrderStatus]
