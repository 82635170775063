import type { CheckoutResponse, FormValues } from '~/types'
import { US_STATES } from '~/utils/country'
import { createFormField } from './FormUtils'

export type BillingFormValues = FormValues<typeof BILLING_FORM_FIELDS>

export const BILLING_FORM_FIELDS = {
	...createFormField({
		type: 'radio',
		name: 'purchase-role',
		label: "I'm purchasing as a:",
		autoComplete: 'off',
		options: ['business', 'individual'] as const,
		required: true
	}),
	...createFormField({
		type: 'checkbox',
		name: 'separateBilling',
		label: 'Separate Billing Address',
		autoComplete: 'off'
	}),
	...createFormField({ type: 'email', name: 'billing-email', autoComplete: 'off' }),
	...createFormField({
		name: 'billing-name',
		label: 'Billing Contact (optional)',
		placeholder: 'Name Nameson',
		autoComplete: 'billing name'
	}),
	...createFormField({ name: 'billing-email', label: 'Billing Email (optional)', autoComplete: 'billing email' }),
	...createFormField({ name: 'billing-address-line1', autoComplete: 'billing address-line1', required: true }),
	...createFormField({ name: 'billing-address-line2', autoComplete: 'billing address-line2' }),
	...createFormField({
		name: 'billing-postal-code',
		placeholder: 'Postal Code',
		autoComplete: 'billing postal-code',
		required: true
	}),
	...createFormField({ name: 'billing-post-town', placeholder: 'Post Town', autoComplete: 'on', required: true }),
	...createFormField({
		name: 'billing-state',
		placeholder: 'State',
		autoComplete: 'billing address-level1',
		options: US_STATES.map((s) => ({ label: s.name, value: s.code })),
		required: true
	}),
	...createFormField({
		name: 'billing-organization',
		label: 'Company Name',
		placeholder: 'Business Name',
		autoComplete: 'organization',
		required: true
	}),
	...createFormField({
		name: 'vatnumber',
		label: 'Tax Number',
		description: 'Company must be in same country as shipping destination',
		placeholder: '123456789MVA',
		autoComplete: 'off'
	}),
	...createFormField({
		name: 'ponumber',
		label: 'PO Number (optional)',
		placeholder: '173649-2',
		autoComplete: 'off'
	})
} as const

export function getBillingFormValues(checkout: CheckoutResponse): Required<BillingFormValues> {
	const properties = checkout.properties
	return {
		'purchase-role': properties.billing_organization ? 'business' : 'individual',
		'separateBilling': properties.billing_line_1 ? true : false,
		'billing-email': properties.billing_email ?? '',
		'billing-name': properties.billing_name ?? '',
		'billing-address-line1': properties.billing_line_1 ?? '',
		'billing-address-line2': properties.billing_line_2 ?? '',
		'billing-postal-code': properties.billing_postal_code ?? '',
		'billing-post-town': properties.billing_city ?? '',
		'billing-state': properties.billing_state ?? '',
		'billing-organization': properties.billing_organization ?? '',
		'vatnumber': properties.billing_vat_number ?? '',
		'ponumber': properties.billing_po_number ?? ''
	}
}

export function getDefaultBillingFormValues(): Required<BillingFormValues> {
	return {
		'purchase-role': 'business',
		'separateBilling': false,
		'billing-email': '',
		'billing-name': '',
		'billing-address-line1': '',
		'billing-address-line2': '',
		'billing-postal-code': '',
		'billing-post-town': '',
		'billing-state': '',
		'billing-organization': '',
		'vatnumber': '',
		'ponumber': ''
	}
}
