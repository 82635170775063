import { isPresent } from 'ts-extras'
import type { AddressSource, GQL, ValidationError, ValidationStates, WithValidation } from '~/types'

export function toFieldError(err: Error | GQL.AvalaraMessage) {
	return 'refersTo' in err ? { field: getFieldName(err.refersTo), message: err.details } : err
}

export function getFieldName(key: AddressSource): NonNullable<ValidationError['field']> | null {
	switch (key) {
		case 'Address':
			return null
		case 'Address.City':
			return 'post-town'
		case 'Address.Country':
			return 'country'
		case 'Address.Line0':
		case 'Address.Line1':
			return 'address-line1'
		case 'Address.Line2':
			return 'address-line2'
		case 'Address.Line3':
			return 'address-line3'
		case 'Address.PostalCode':
			return 'postal-code'
	}
}

export function isValidFormDetails<T extends WithValidation<Record<string, any>>>(
	details: T
): details is Exclude<T, ValidationStates> {
	if (!isPresent(details)) return false
	if (typeof details === 'string') return false
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if ('errors' in details) return false
	return true
}
