import Stripe from 'stripe'
import { isPresent } from 'ts-extras'
import type { SetOptional } from 'type-fest'
import type { GQL, Market, Nullable, PriceDetails, PricingValue, SimpleLineItem } from '~/types'
import { DEFAULT_TAX_NAME } from './constants'
import { stripeId } from './stripe'

export function applyCoupon<T extends Pick<GQL.Price, 'unit_amount' | 'product' | 'currency'>>(
	price: T,
	coupons: Stripe.Coupon[]
) {
	const coupon = coupons.find((c) =>
		c.applies_to ? c.applies_to.products.find((id) => id === stripeId(price.product)) : true
	)
	let unitAmount = price.unit_amount ?? 0
	if (coupon) {
		if (coupon.percent_off) unitAmount = unitAmount - (unitAmount * coupon.percent_off) / 100
		else if (coupon.amount_off)
			unitAmount = unitAmount - (coupons?.[0].currency_options?.[price.currency]?.amount_off ?? coupon.amount_off)
	}
	return {
		...price,
		unit_amount: unitAmount
	}
}

export function getPriceDetails(
	lineItem: SetOptional<SimpleLineItem, 'id'>,
	coupons: Stripe.Coupon[],
	market: Nullable<Market>,
	priceType?: GQL.PriceType
): PriceDetails {
	const {
		product: { price, depositPrice, offer, ...product },
		quantity
	} = lineItem

	if (!price) throw new Error('Product has no default price')
	if (priceType === undefined) priceType = depositPrice ? 'deposit_initial' : 'one_time'

	const taxBehavior = price.tax_behavior ?? 'unspecified'
	const subtotal = getPrice(applyCoupon(price, coupons)) * quantity
	const compareAtPrice =
		(product.compareAtPrice !== null ? getPrice(product.compareAtPrice) : (price.unit_amount || 0) / 100) * quantity
	const depositAmount = depositPrice ? getPrice(depositPrice) * quantity : null
	const depositPercentage = depositAmount !== null ? (depositAmount / subtotal) * 100 : null

	const total = subtotal

	let amountDue: number
	switch (priceType) {
		case 'deposit_initial':
			if (!isPresent(depositAmount))
				throw new Error(`Product ${product._id} has no deposit price in ${market?._id}`)
			amountDue = depositAmount
			break

		case 'one_time':
			amountDue = total
			break

		case 'deposit_remaining':
			if (!isPresent(depositAmount))
				throw new Error(`Product ${product._id} has no deposit price in ${market?._id}`)
			amountDue = total - depositAmount
			break

		case 'recurring':
			throw new Error(`Unsupported price type: ${priceType}`)
	}
	return {
		priceType,
		subtotal,
		compareAtPrice,
		amountDue,
		savings: compareAtPrice ? compareAtPrice - subtotal : 0,
		depositAmount,
		depositPercentage,
		depositTax: null,
		offer,
		tax: null,
		taxPercent: null,
		total,
		remaining: total - amountDue,
		taxBehavior,
		taxName: market?.vatName ?? DEFAULT_TAX_NAME,
		currency: price.currency.toLocaleUpperCase(),
		displayCurrency: market?.currencySymbol ?? null,
		currencyPosition: market?.currencyPosition || 'auto',
		thousandSeparator: market?.thousandSeparator ?? ''
	}
}

export interface MarketPriceDetails
	extends Pick<Partial<Market>, 'thousandSeparator' | 'currencySymbol' | 'currencyPosition'> {
	currency: string
}

export function displaySimplePrice(value: number, market: MarketPriceDetails) {
	const amount = (value % 1 == 0 ? value : value.toFixed(2))
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, market.thousandSeparator ?? '')
	const currency = getCurrencySymbol(market.currency || '', { currencySymbol: market.currencySymbol })
	const symbolAtEnd = market.currencyPosition === 'right' || currency.length === 3
	return symbolAtEnd ? `${amount} ${currency}` : `${currency}${amount}`
}

/**
 *
 * @param details
 * @param key Can be a key of priceDetails, or a number to be displayed with correct currency format
 * @param fallbackValue
 * @returns
 */
export function displayPrice<T extends PriceDetails, K extends keyof T>(
	details: T,
	key: K | Exclude<PricingValue, 'zeroTaxReason' | 'coupon'> | number = 0,
	market: Nullable<Market>,
	fallbackValue?: string | number
) {
	switch (key) {
		case 'remainingDepositPercentage': {
			const value = 100 - (details.depositPercentage ?? 100)
			return value === 0 || value === 100 ? value : value.toPrecision(2)
		}

		case 'depositPercentage':
		case 'taxPercent': {
			const value = details[key]
			if (typeof value !== 'number') return value
			return value === 0 ? value : value.toPrecision(2)
		}

		default: {
			const value =
				typeof key === 'number'
					? key ?? fallbackValue
					: key === 'remaining'
					? details.total - details.amountDue
					: details[key]
			if (!isPresent(value)) return fallbackValue ?? value
			if (typeof value !== 'number') return value
			return displaySimplePrice(value, { ...market, ...details })
		}
	}
}

export function hasDecimals(n: number) {
	return typeof n === 'number' && n % 1 !== 0
}

export type Price = Pick<GQL.Price, 'currency' | 'unit_amount' | 'tax_behavior'>
export type PriceValue = Nullable<Price | string | number>
export function isPrice(n: any): n is Price {
	if (!n || typeof n !== 'object') return false
	return 'currency' in n
}

export function isPriceInput(n: any): n is PriceValue {
	return ['string', 'number'].includes(typeof n) || isPrice(n)
}

export function parseNumber(n: Nullable<string | number>): number {
	const result = Number.parseFloat((n as string) || '0')
	return result || 0
}

type PriceText = Exclude<PriceValue, Price>
type PriceObject = Exclude<PriceValue, string | number>

export function getCurrencySymbol(
	currency: string,
	market: Nullable<{ currencySymbol?: Nullable<Market['currencySymbol']> }>
): string {
	if (market?.currencySymbol) return getCurrencySymbol(market.currencySymbol, null)
	switch (currency) {
		case 'USD':
			return '$'
		case 'EUR':
			return '€'
		case 'GBP':
			return '£'
		default:
			return currency
	}
}

/**
 * Formats price according to current locale and currency
 * @param price {@link PriceValue}
 * @param asText if true format price with currency symbol, if not, return price as float
 * @param decimals Number of decimals to format with. Only relevant if asText is true.
 * Will always display decimals on prices that would otherwise require rounding
 */
export function getPrice(price: PriceText | PriceObject): number {
	if (!isPresent(price)) return 0
	if (isPrice(price)) {
		const value = parseNumber(price.unit_amount) / 100
		return value
	} else {
		return parseNumber(price)
	}
}
