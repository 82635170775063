import { objectEntries } from 'ts-extras'
import states from './states.json'

export type StateCode = State['code']

export interface State {
	code: keyof typeof states
	name: string
}
export const US_STATES: State[] = objectEntries(states).map(([code, name]) => ({ code, name }))
