import type { CheckoutResponse, FormValues } from '~/types'
import { createFormField } from './FormUtils'

export type PaymentFormValues = FormValues<typeof PAYMENT_FORM_FIELDS>

export const DEFAULT_PAYMENT_METHOD = 'card'

export const PAYMENT_FORM_FIELDS = {
	...createFormField({
		label: 'Coupon code',
		name: 'coupon',
		autoComplete: 'off',
		required: true
	}),
	...createFormField({
		type: 'radio',
		label: 'Select Payment Method',
		name: 'payment-method',
		autoComplete: 'off',
		options: [
			{ value: 'card', label: 'Card or financing' },
			{ value: 'invoice', label: 'Invoice' }
		] as const,
		required: true
	}),
	...createFormField({
		type: 'checkbox',
		name: 'savePaymentDetails',
		label: 'Save payment details',
		autoComplete: 'off'
	})
} as const

export function getPaymentFormValues(checkout: CheckoutResponse): Required<PaymentFormValues> {
	const properties = checkout.properties
	return {
		'coupon': checkout.properties.coupon_codes?.[0]?.split('__').pop() ?? '',
		'payment-method': properties.payment_method ?? DEFAULT_PAYMENT_METHOD,
		'savePaymentDetails': false
	}
}

export function getDefaultPaymentFormValues(): Required<PaymentFormValues> {
	return {
		'coupon': '',
		'payment-method': DEFAULT_PAYMENT_METHOD,
		'savePaymentDetails': false
	}
}
