/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ClientConfig } from 'next-sanity'
import type { SetRequired } from 'type-fest'

export const SanityConfig: SetRequired<ClientConfig, 'projectId' | 'dataset' | 'apiVersion' | 'useCdn'> = {
	/**
	 * Find your project ID and dataset in `sanity.json` in your studio project.
	 * These are considered “public”, but you can use environment variables
	 * if you want differ between local dev and production.
	 *
	 * https://nextjs.org/docs/basic-features/environment-variables
	 **/
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
	dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
	apiVersion: '2021-10-21', // Learn more: https://www.sanity.io/docs/api-versioning
	/**
	 * Set useCdn to `false` if your application require the freshest possible
	 * data always (potentially slightly slower and a bit more expensive).
	 * Authenticated request (like preview) will always bypass the CDN
	 **/
	useCdn: process.env.NODE_ENV === 'production',
	token: process.env.NEXT_PUBLIC_SANITY_API_TOKEN
}
