export function isTouchDevice() {
	if (typeof window === 'undefined') return false
	return typeof window.ontouchstart == 'function' || navigator.maxTouchPoints > 0
}

export function getSafariVersion() {
	if (typeof navigator === 'undefined') return null
	if (!navigator.userAgent?.includes('Safari')) return null
	const ua = navigator.userAgent
	const match = ua.match(/Version\/(\d+).(\d+)/)
	if (!match) return null
	return { major: match[1], minor: match[2] }
}
