/* eslint-disable @next/next/no-img-element */
import { screenSizes } from '@nomonosound/gravity'
import { ConsentManager as SegmentConsentManager } from '@segment/consent-manager'
import type { ConsentManagerProps } from '@segment/consent-manager/types/types'
import * as snippet from '@segment/snippet'
import Link from 'next/link'
import { Router } from 'next/router'
import Script from 'next/script'
import { type FunctionComponent } from 'react'
import styled from 'styled-components'
import { useSettings } from '~/utils'
import { Paragraph } from '../FontStyles'
export { openConsentManager } from '@segment/consent-manager'

function renderAnalyticsSnippet() {
	const opts: snippet.Options = {
		apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
		// note: the page option only covers SSR tracking.
		// Page.js is used to track other events using `window.analytics.page()`
		page: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' // only call analytics.page in production
	}

	return process.env.NODE_ENV === 'development' ? snippet.max(opts) : snippet.min(opts)
}

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
	if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') window.analytics?.page(url)
})

const ConsentWrapper = styled.div`
	z-index: 100;
	position: fixed;
	pointer-events: none;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 1rem;
	@media (min-width: ${screenSizes.medium}) {
		padding: 2rem;
	}

	// container
	.css-1acyxzg {
		display: grid;
		grid-template-rows: 1fr auto;
		grid-template-columns: 1fr auto;
		grid-gap: 1rem;
		align-items: center;

		max-width: 27rem;
		margin: 0 auto 0 0;
		border-radius: 1rem;
		padding: 1rem;
		text-align: left;

		background-color: var(--background-default);
		font: var(--text-sm);
		pointer-events: all;
	}

	// Text container
	.css-ow1vry {
		display: contents;

		// cookie text button
		> p:first-child {
			&::before {
				content: 'Cookie preferences';
				display: block;
				margin-bottom: 0.5rem;
				color: var(--text-default);
				font: var(--heading-md);
			}
			color: var(--text-secondary);
			grid-row: 1;
			grid-column: 1 / -1;
		}
		// preferences button
		> p:last-child {
			grid-row: 2;
			grid-column: 1;

			button {
				opacity: 0.5;
			}
		}

		a {
			color: var(--primary);
		}
	}

	// button group
	.css-1opfoum {
		grid-row: 2;
		grid-column: 2;

		display: flex;
		flex-flow: row-reverse nowrap;
		gap: 0.5rem;

		> button {
			width: 100%;
			display: flex;
			align-items: center;
			font: var(--button-sm);
			margin: 0;
			padding: 0.5rem;
			white-space: nowrap;
			border-radius: 0.5rem;

			box-shadow: none;
			:hover {
				box-shadow: none;
			}
		}

		// accept button
		.css-1uzjr4x {
			color: var(--background-default);
			background: var(--fill-primary);
			&:hover {
				background: var(--fill-primary-hover);
			}
			&:active {
				background: var(--fill-primary-pressed);
			}
		}

		// decline button
		.css-1k0se6 {
			color: var(--text-default);
			background: transparent;
			border: 1px solid var(--text-disabled);

			&:hover {
				color: var(--background-default);
				background: var(--text-default);
				border-color: var(--text-default);
			}
		}
	}
`

export const ConsentManager: FunctionComponent = () => {
	const { siteSettings } = useSettings()
	const { cookieConsent } = siteSettings || {}

	if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') return null

	const consentProps: ConsentManagerProps = {
		writeKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
		preferencesDialogTitle: 'Website Data Collection Preferences',
		preferencesDialogContent: (
			<span>
				We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze
				site traffic, deliver personalized advertisements, and increase the overall performance of our site.
			</span>
		),
		cancelDialogTitle: 'Are you sure you want to cancel?',
		cancelDialogContent:
			'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.',
		bannerActionsBlock: true,
		bannerHideCloseButton: true,
		bannerSubContent: 'You can change your preferences at any time.',
		...cookieConsent,
		bannerContent: (
			<Paragraph type="textMd">
				We use cookies (and other similar technologies) to collect data to improve your experience on our site.
				By using our website, you are agreeing to the collection of data as described in our{' '}
				<Link href="privacy-notice">Privacy Policy</Link>.
			</Paragraph>
		),
		shouldRequireConsent: () => process.env.NODE_ENV === 'production'
	}

	return (
		<>
			<Script id="show-banner" dangerouslySetInnerHTML={{ __html: renderAnalyticsSnippet() }} />
			<ConsentWrapper className="global-theme dark-theme">
				<SegmentConsentManager {...consentProps} bannerActionsBlock bannerHideCloseButton />
			</ConsentWrapper>
		</>
	)
}
