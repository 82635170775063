export const blogpostQuery = `//groq
*[_type == "blogPost" && slug.current == $slug] {
	...,
	mainImage {
		...,
		asset->
	}
}[0]
`

export const latestBlogpostsQuery = `//groq
*[_type == "blogPost" && defined(slug.current)] {
		_id,
		title,
		slug,
		_updatedAt,
		_createdAt,
		publishedAt,
}
`
