import { checkoutSettingsQuery } from './checkoutSettingsQuery'
import { pageTypeFilter } from './pageQuery'
import { linkFragment, pageLinkFragment } from './sanityFragments'

export const globalSettingsQuery = `//groq
{
	"siteSettings": *[_id == "siteSettings"] {
		...,
		headerItems[] {
			...,
			${linkFragment},
			menus[]->{
				...,
				items[] {
					...,
					${linkFragment}
				}
			}
		},
		legalItems[]->{
			...,
			items[] {
				...,
				${linkFragment}
			}
		},
		footerItems[]->{
			...,
			items[] {
				...,
				${linkFragment}
			}
		},
		opCount
	},

	"plans": *[_type == "plan"] {...} | order(price asc),

	"markets": *[_type == "market"] {...},

	"checkoutSettings": ${checkoutSettingsQuery},

	"kbSettings": *[_id == "kbSettings"] {
		...,
		menus[]->{
			...,
			_type == "pageCategory" => {
				"items": *[${pageTypeFilter} && references(^._id)] {
					"_type": "pageCategory",
					"pageReference": {
						${pageLinkFragment}
					}
				}
			},

			_type == "menu" => {
				link {
					${linkFragment}
				},
				items[] {
					...,
					${linkFragment}
				}
			}
		},
	},

	"legalSettings": *[_id == "legalSettings"] {
		...,
		menus[]->{
			...,
			_type == "pageCategory" => {
				"items": *[${pageTypeFilter} && references(^._id)] {
					"_type": "pageCategory",
					"pageReference": {
						${pageLinkFragment}
					}
				}
			},

			_type == "menu" => {
				link {
					${linkFragment}
				},
				items[] {
					...,
					${linkFragment}
				}
			}
		},
	}
}
`
