import type { TypedDocumentNode as Document } from '@graphql-typed-document-node/core'
import { addBreadcrumb } from '@sentry/react'
import type { OperationDefinitionNode } from 'graphql'
import { print } from 'graphql/language/printer'
import { capitalize, isArray, omitBy } from 'lodash'
import type { GQL } from '~/types'
import { BASE_URL } from '../constants'

export function getDocumentName<T, V>(doc: Document<T, V>): string | null {
	const node = doc?.definitions.find((def): def is OperationDefinitionNode => def.kind === 'OperationDefinition')
	return node ? node.name?.value + capitalize(node.operation) : null
}

export async function gqlClient<T, V extends {}>(
	gqlQuery: Document<T, V>,
	variables?: V,
	headers?: HeadersInit
): Promise<GQL.Response<T>> {
	const query = typeof gqlQuery === 'string' ? gqlQuery : print(gqlQuery)
	try {
		const response = await fetch(`${BASE_URL}/api/graphql`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				...headers
			},
			body: JSON.stringify({
				query,
				variables
			})
		})
		if (response.ok) {
			const json = await response.json()
			return extractData(json)
		} else {
			const status = `[${response.status}: ${response.statusText}] `
			const message = (await response.text()).replace(/\s+/g, ' ')
			addBreadcrumb({
				type: 'gql-api',
				message: status + message,
				level: 'warning',
				data: { 'http.status': response.status }
			})
			return { data: null, errors: [new Error(message)] }
		}
	} catch (e) {
		const err = e as Error
		addBreadcrumb({
			type: 'storefront-api',
			level: 'warning',
			message: err.message,
			data: { operation: getDocumentName(gqlQuery), ...variables, error: err.name, stack: err.stack }
		})
		return {
			data: null,
			errors: [{ operation: getDocumentName(gqlQuery), ...err }]
		}
	}
}

function extractData<T>(res: GQL.Response<T>): GQL.Response<T> {
	// TODO: Return data even if errors are present
	if (isArray(res.data)) {
		return res
	} else if (res.data) {
		const data: Record<string, any> = res.data as any

		// Extract response data. Can't use data key as mutations return an abritrary name
		const resultDict = omitBy(data, (_, key) => key.toLowerCase().includes('errors'))
		const entries = Object.values(resultDict) // Should only be entry left in response now

		return {
			...res,
			data: entries.pop()
		}
	} else {
		return res
	}
}
