import { linkFragment, pageLinkFragment } from './sanityFragments'

export const contentQuery = /* groq */ `
	content[] {
		...,
		content[] {
			...,
			markDefs[] {
				...,
				${linkFragment}
			},
			_type == 'faqBlock' => {
				faq->{
					...,
					questions[]->
				},
			}
		},
		media[] {
			...,
			asset->
		},
		mainImage {
			asset->
		},
		mainImageMobile {
			asset->
		},
		heroImage {
			...,
			asset->
		},
		product->,

		list[] {
			...,
			${linkFragment}
		},
		_type == 'faqArticle' => {
			...,
			faqGroup->{
				...,
				questions[]->
			},
		},
		_type == 'faqBlock' => {
			faq->{
				...,
				questions[]->
			},
		},

		_type == 'kbOverview' => {
			...,
			categories[] {
				...,
				ref->,
				^.type == 'list' => {
					"pages": *[_type == "kbArticle" && references(^.ref._ref) && _id != ^.^.^._id] {
						${pageLinkFragment}
					}
				}
			}
		}
	}
`
