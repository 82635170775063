import type { Theme as GravityTheme } from '@nomonosound/gravity'
import { useContext } from 'react'
import { ThemeContext as StyledThemeContext, ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Except } from 'type-fest'
import type { Theme, ThemeSettings } from '~/types'

declare module 'styled-components' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Partial<GravityTheme>, StyledTheme {}
}

export interface CompatThemeSettings extends Except<ThemeSettings, '_type' | 'color' | 'backgroundColor'> {
	color?: ThemeSettings['color'] | Theme
	backgroundColor?: ThemeSettings['backgroundColor'] | Theme
}

export interface StyledTheme {
	/** @deprecated use settings instead */
	current: Theme | undefined
	settings: CompatThemeSettings | null
}

export const defaultTheme = 'light'

export const ThemeContext = StyledThemeContext
export const ThemeProvider = StyledThemeProvider

/**
 *
 * @param override if provided, override parent theme unless current theme is set to inherit
 * @returns
 */
export function useTheme(override?: Theme | Partial<StyledTheme>): StyledTheme {
	const ctx = useContext(ThemeContext)
	if (!override || override === 'inherit')
		return {
			current: 'inherit',
			settings: null
		}
	else if (typeof override === 'string')
		return {
			current: override,
			settings: {
				backgroundColor: 'theme',
				color: 'theme'
			}
		}
	else
		return {
			current: override.current ?? 'inherit',
			settings: {
				...ctx.settings,
				...override.settings,
				color: override.settings?.color ?? ctx.settings?.color ?? override.current,
				backgroundColor: override.settings?.backgroundColor ?? ctx.settings?.backgroundColor ?? override.current
			}
		}
}
