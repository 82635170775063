import { nextReduxCookieMiddleware, wrapMakeStore } from 'next-redux-cookie-wrapper'
import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, compose, createStore, Store } from 'redux'
import createSagaMiddleware, { EffectMiddleware } from 'redux-saga'
import type { State } from '~/types'
import { DEV } from '~/utils/constants'
import { rootReducer } from './reducers'
import SagaManager from './sagas'

// Create dummy which will be replaced in configureStore
const actionInterceptor: EffectMiddleware = (next) => (effect) => next(effect)
const effectMiddleware = (next: any) => actionInterceptor(next)

const sagaMiddleware = createSagaMiddleware({ effectMiddlewares: [effectMiddleware] })

const middlewares = [
	nextReduxCookieMiddleware({
		expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
		subtrees: ['cart.lineItems', 'market.country']
	}),
	sagaMiddleware
]

const devtools = typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null
const composeEnhancers: typeof compose = devtools ?? compose

function configureStore(initialState?: Partial<State>) {
	const store = createStore(rootReducer(), initialState || {}, composeEnhancers(applyMiddleware(...middlewares)))

	// run sagas
	SagaManager.startSagas(sagaMiddleware)

	if (DEV && module.hot) {
		// Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
		module.hot.accept('./reducers', async () => {
			const reducers = await import('./reducers')
			store.replaceReducer(reducers.rootReducer as any)
		})

		module.hot.accept('./sagas', async () => {
			const newSagaManager = await import('./sagas')
			SagaManager.cancelSagas(store as any)
			newSagaManager.default.startSagas(sagaMiddleware)
		})
	}

	return store
}

const makeStore = wrapMakeStore<Store<State>>(() => configureStore())

export const storeWrapper = createWrapper<Store<State>>(makeStore)
