import Router from 'next/router'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { CreateCheckoutDocument, UpdateBillingDocument, UpdateShippingDocument } from '~/queries'
import { FilterActions, SegmentEvent, State, Step } from '~/types'
import { toBillingInput, toShippingInput } from '~/utils/checkout'
import { getCookie } from '~/utils/cookie'
import { isValidFormDetails, toFieldError } from '~/utils/form'
import { gqlClient } from '~/utils/graphql'
import { identify, track } from '~/utils/track'
import { Action, ActionTypes, Actions } from '../actions'
import type { CheckoutState } from '../reducers/checkout'

export type CheckoutSaveState = CheckoutState['details']

export function* checkoutSagas() {
	yield* takeLatest<ActionTypes, any>('CHECKOUT_STEP_UPDATE', updateStep)
	yield* takeLatest<ActionTypes, any>('CHECKOUT_ID_UPDATE', onIdUpdated)

	yield* takeLatest<ActionTypes, any>('CHECKOUT_VALIDATE_USER', validateStep)
	yield* takeLatest<ActionTypes, any>('CHECKOUT_VALIDATE_SHIPPING', validateStep)
	yield* takeLatest<ActionTypes, any>('CHECKOUT_VALIDATE_BILLING', validateStep)

	yield* takeLatest<ActionTypes, any>('CHECKOUT_VALIDATE_RESULT', stepValidated)
}

function* onIdUpdated(action: Action<'CHECKOUT_ID_UPDATE'>) {
	const url = new URL(location.href)
	url.searchParams.set('id', action.payload)
	yield* call(Router.replace, url, undefined, { shallow: true })
}

function* updateStep(action: Action<'CHECKOUT_STEP_UPDATE'>) {
	const checkout = yield* select((s: State) => s.checkout)
	const current = checkout.step
	const next = action.payload
	const url = new URL(location.href)
	url.searchParams.set('step', next.toString())

	if (current === 1 || next === 2) {
		url.searchParams.delete('code')
		url.searchParams.delete('state')
	}

	yield* call(Router.push, url, undefined, { shallow: true })
	track(SegmentEvent.CheckoutStepViewed, { checkout_id: checkout.id, step: next })
	trackSpecificStep(current, checkout.completedSteps, checkout.id)
}

function trackSpecificStep(currentStep: number, completedStep: number, checkoutId: string | null) {
	if (completedStep > currentStep - 2) {
		return
	}

	const eventStarted =
		currentStep === 2
			? SegmentEvent.ShippingStepStarted
			: currentStep === 3
			? SegmentEvent.BillingStepStarted
			: currentStep === 4
			? SegmentEvent.PaymentStepStarted
			: null

	const eventCompleted =
		currentStep === 3
			? SegmentEvent.ShippingStepCompleted
			: currentStep === 4
			? SegmentEvent.BillingStepCompleted
			: null

	if (eventCompleted) track(eventCompleted, { checkout_id: checkoutId })
	if (eventStarted) track(eventStarted, { checkout_id: checkoutId })
}

function* stepValidated(action: Action<'CHECKOUT_VALIDATE_RESULT'>) {
	if (!isValidFormDetails(action.payload)) return
	const checkout = yield* select((s: State) => s.checkout)
	let step: Step
	switch (action.meta) {
		case 'user':
			step = 1
			break
		case 'shipping':
			step = 2
			break
		case 'billing':
			step = 3
			break
		case 'payment':
			step = 4
			break
	}
	track(SegmentEvent.CheckoutStepCompleted, {
		checkout_id: checkout.id,
		step,
		payment_method: 'method' in action.payload ? action.payload.method : undefined
	})
}

function* validateStep(action: FilterActions<Action, 'CHECKOUT_VALIDATE_'>) {
	const checkout = yield* select((s: State) => s.checkout)
	const lineItems = yield* select((s: State) => s.cart.lineItems)
	const country = yield* select((s: State) => s.market.country)
	const checkoutID = checkout.id
	const type = action.type.split('_').pop()?.toLowerCase() as keyof CheckoutState['details']

	try {
		switch (action.type) {
			case 'CHECKOUT_VALIDATE_USER': {
				const res = yield* call(() =>
					gqlClient(CreateCheckoutDocument, {
						email: action.payload.email,
						lineItems: lineItems.map((item) => ({ id: item.id, quantity: item.quantity })),
						country: country?.code,
						cjevent: getCookie('cje'),
						checkoutId: new URL(location.href).searchParams.get('id'),
						utm_source: getCookie('utm_source'),
						utm_medium: getCookie('utm_medium'),
						utm_campaign: getCookie('utm_campaign')
					})
				)
				if (res.errors) yield* put(Actions.setValidationResult({ errors: res.errors }, type))
				else if (res.data) {
					yield* put(Actions.setCheckoutID(res.data.id))
					const url = new URL(location.href)
					url.searchParams.set('id', res.data.id)
					yield* call(Router.replace, url, undefined, { shallow: true })
					yield* put(Actions.setStep(2))
					yield* put(Actions.setValidationResult(action.payload, type))
					identify({ email: action.payload.email })
				}
				if (checkout.completedSteps === 0) track(SegmentEvent.CheckoutStarted, { order_id: res.data?.id })
				break
			}

			case 'CHECKOUT_VALIDATE_SHIPPING': {
				if (!isValidFormDetails(checkout.details.user)) {
					yield* put(Actions.setValidationResult({ errors: [{ message: 'Missing user' }] }, type))
					break
				}
				if (!checkoutID) {
					yield* put(
						Actions.setValidationResult({ errors: [{ message: 'Failed to create checkout' }] }, type)
					)
					break
				}
				const shipping = toShippingInput({ ...action.payload, email: checkout.details.user.email }, country)

				const res = yield* call(() => gqlClient(UpdateShippingDocument, { checkoutID, shipping }))
				const errors = (res.data && 'errors' in res.data ? res.data.errors : null) || res.errors
				if (errors) yield* put(Actions.setValidationResult({ errors: errors?.map(toFieldError) }, type))
				else if (res.data) {
					yield* put(Actions.setStep(3))
					yield* put(Actions.setValidationResult(shipping, type))
				}
				break
			}

			case 'CHECKOUT_VALIDATE_BILLING': {
				if (!checkoutID) {
					yield* put(
						Actions.setValidationResult({ errors: [{ message: 'Failed to create checkout' }] }, type)
					)
					break
				}
				const shipping = checkout.details.shipping
				if (isValidFormDetails(shipping)) {
					const billing = toBillingInput(action.payload, shipping)
					const res = yield* call(() => gqlClient(UpdateBillingDocument, { checkoutID, billing }))
					if (res.errors) yield* put(Actions.setValidationResult({ errors: res.errors }, type))
					else if (res.data) {
						yield* put(Actions.setStep(4))
						yield* put(Actions.setValidationResult(billing, type))
					}
				} else {
					yield* put(Actions.setValidationResult({ errors: [{ message: 'Invalid shipping details' }] }, type))
				}
				break
			}
		}
	} catch (e) {
		yield* put(Actions.setValidationResult({ errors: [e as Error] }, type))
	}
}
