/* eslint-disable @typescript-eslint/no-namespace */

import type { Stripe } from 'stripe'
import type { CountryCode } from '~/types'

/**
 * Collection of all Stripe supported VAT IDs.
 * From https://stripe.com/docs/invoicing/taxes/account-tax-ids?dashboard-or-api=api#supported-tax-id
 * @TODO: Switch to {@type satisfies} when build/lint pipeline supports TS 4.9
 */
export const VatIDs: Partial<Record<CountryCode | 'EU', readonly Stripe.TaxIdCreateParams.Type[]>> = {
	AU: [
		/**
		 * Australian Business Number (AU ABN)
		 * @example 12345678912
		 */
		'au_abn',
		/**
		 * Australian Taxation Office Reference Number
		 * @example 123456789123
		 */
		'au_arn'
	] as const,
	AT: [
		/**
		 * European VAT number
		 * @example ATU12345678
		 */
		'eu_vat'
	] as const,
	BE: [
		/**
		 * European VAT number
		 * @example BE0123456789
		 */
		'eu_vat'
	] as const,
	BR: [
		/**
		 * Brazilian CNPJ number
		 * @example 01.234.456/5432-10
		 */
		'br_cnpj',

		/**
		 * Brazilian CPF number
		 * @example 123.456.789-87
		 */
		'br_cpf'
	] as const,
	BG: [
		/**
		 * Bulgaria Unified Identification Code
		 * @example 123456789
		 */
		'bg_uic',

		/**
		 * European VAT number
		 * @example BG0123456789
		 */
		'eu_vat'
	] as const,
	CA: [
		/**
		 * Canadian BN
		 * @example 123456789
		 */
		'ca_bn',
		/**
		 * Canadian GST/HST number
		 * @example 123456789RT0002
		 */
		'ca_gst_hst',

		/**
		 * Canadian PST number (British Columbia)
		 * @example PST-1234-5678
		 */
		'ca_pst_bc',

		/**
		 * Canadian PST number (Manitoba)
		 * @example 123456-7
		 */
		'ca_pst_mb',

		/**
		 * Canadian PST number (Saskatchewan)
		 * @example 1234567
		 */
		'ca_pst_sk',

		/**
		 * Canadian QST number (Québec)
		 * @example 1234567890TQ1234
		 */
		'ca_qst'
	] as const,
	CL: [
		/**
		 * Chilean TIN
		 * @example 12.345.678-K
		 */
		'cl_tin'
	] as const,
	HR: [
		/**
		 * European VAT number
		 * @example HR12345678912
		 */
		'eu_vat'
	] as const,
	CY: [
		/**
		 * European VAT number
		 * @example CY12345678Z
		 */
		'eu_vat'
	] as const,
	CZ: [
		/**
		 * European VAT number
		 * @example CZ1234567890
		 */
		'eu_vat'
	] as const,
	DK: [
		/**
		 * European VAT number
		 * @example DK12345678
		 */
		'eu_vat'
	] as const,
	EG: [
		/**
		 * Egyptian Tax Identification Number
		 * @example 123456789
		 */
		'eg_tin'
	] as const,
	EE: [
		/**
		 * European VAT number
		 * @example EE123456789
		 */
		'eu_vat'
	] as const,
	EU: [
		/**
		 * European One Stop Shop VAT number for non-Union scheme
		 * @example EU123456789
		 */
		'eu_oss_vat'
	] as const,
	FI: [
		/**
		 * European VAT number
		 * @example FI12345678
		 */
		'eu_vat'
	] as const,
	FR: [
		/**
		 * European VAT number
		 * @example FRAB123456789
		 */
		'eu_vat'
	] as const,
	GE: [
		/**
		 * Georgian VAT
		 * @example 123456789
		 */
		'ge_vat'
	] as const,
	DE: [
		/**
		 * European VAT number
		 * @example DE123456789
		 */
		'eu_vat'
	] as const,
	GR: [
		/**
		 * European VAT number
		 * @example EL123456789
		 */
		'eu_vat'
	] as const,
	HK: [
		/**
		 * Hong Kong BR number
		 * @example 12345678
		 */
		'hk_br'
	] as const,
	HU: [
		/**
		 * Hungary tax number (adószám)
		 * @example 12345678-1-23
		 */
		'hu_tin',

		/**
		 * European VAT number
		 * @example HU12345678912
		 */
		'eu_vat'
	] as const,
	IS: [
		/**
		 * Icelandic VAT
		 * @example 123456
		 */
		'is_vat'
	] as const,
	IN: [
		/**
		 * Indian GST number
		 * @example 12ABCDE3456FGZH
		 */
		'in_gst'
	] as const,
	ID: [
		/**
		 * Indonesian NPWP number
		 * @example 12.345.678.9-012.345
		 */
		'id_npwp'
	] as const,
	IE: [
		/**
		 * European VAT number
		 * @example IE1234567AB
		 */
		'eu_vat'
	] as const,
	IL: [
		/**
		 * Israel VAT
		 * @example 000012345
		 */
		'il_vat'
	] as const,
	IT: [
		/**
		 * European VAT number
		 * @example IT12345678912
		 */
		'eu_vat'
	] as const,
	JP: [
		/**
		 * Japanese Corporate Number (*Hōjin Bangō*)
		 * @example 1234567891234
		 */
		'jp_cn',
		/**
		 * Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)
		 * @example 12345
		 */
		'jp_rn',
		/**
		 * Japanese Tax Registration Number (*Tōroku Bangō*)
		 * @example T1234567891234
		 */
		'jp_trn'
	] as const,
	KE: [
		/**
		 * Kenya Revenue Authority Personal Identification Number
		 * @example P000111111A
		 */
		'ke_pin'
	] as const,
	LV: [
		/**
		 * European VAT number
		 * @example LV12345678912
		 */
		'eu_vat'
	] as const,
	LI: [
		/**
		 * Liechtensteinian UID number
		 * @example CHE123456789
		 */
		'li_uid'
	] as const,
	LT: [
		/**
		 * European VAT number
		 * @example LT123456789123
		 */
		'eu_vat'
	] as const,
	LU: [
		/**
		 * European VAT number
		 * @example LU12345678
		 */
		'eu_vat'
	] as const,
	MY: [
		/**
		 * Malaysian FRP number
		 * @example 12345678
		 */
		'my_frp',
		/**
		 * Malaysian ITN
		 * @example C 1234567890
		 */
		'my_itn',
		/**
		 * Malaysian SST number
		 * @example A12-3456-78912345
		 */
		'my_sst'
	] as const,
	MT: [
		/**
		 * European VAT number
		 * @example MT12345678
		 */
		'eu_vat'
	] as const,
	MX: [
		/**
		 * Mexican RFC number
		 * @example ABC010203AB9
		 */
		'mx_rfc'
	] as const,
	NL: [
		/**
		 * European VAT number
		 * @example NL123456789B12
		 */
		'eu_vat'
	] as const,
	NZ: [
		/**
		 * New Zealand GST number
		 * @example 123456789
		 */
		'nz_gst'
	] as const,
	NO: [
		/**
		 * Norwegian VAT number
		 * @example 123456789MVA
		 */
		'no_vat'
	] as const,
	PH: [
		/**
		 * Philippines Tax Identification Number
		 * @example 123456789012
		 */
		'ph_tin'
	] as const,
	PL: [
		/**
		 * European VAT number
		 * @example PL1234567890
		 */
		'eu_vat'
	] as const,
	PT: [
		/**
		 * European VAT number
		 * @example PT123456789
		 */
		'eu_vat'
	] as const,
	RO: [
		/**
		 * European VAT number
		 * @example RO1234567891
		 */
		'eu_vat'
	] as const,
	RU: [
		/**
		 * Russian INN
		 * @example 1234567891
		 */
		'ru_inn',
		/**
		 * Russian KPP
		 * @example 123456789
		 */
		'ru_kpp'
	] as const,
	SA: [
		/**
		 * Saudi Arabia VAT
		 * @example 123456789012345
		 */
		'sa_vat'
	] as const,
	SG: [
		/**
		 * Singaporean GST
		 * @example M12345678X
		 */
		'sg_gst',
		/**
		 * Singaporean UEN
		 * @example 123456789F
		 */
		'sg_uen'
	] as const,
	SK: [
		/**
		 * European VAT number
		 * @example SK1234567891
		 */
		'eu_vat'
	] as const,
	SI: [
		/**
		 * Slovenia tax number (davčna številka)
		 * @example 12345678
		 */
		'si_tin',
		/**
		 * European VAT number
		 * @example SI12345678
		 */
		'eu_vat'
	] as const,
	ZA: [
		/**
		 * South African VAT number
		 * @example 4123456789
		 */
		'za_vat'
	] as const,
	KR: [
		/**
		 * Korean BRN
		 * @example 123-45-67890
		 */
		'kr_brn'
	] as const,
	ES: [
		/**
		 * Spanish NIF number (previously Spanish CIF number)
		 * @example A12345678
		 */
		'es_cif',
		/**
		 * European VAT number
		 * @example ESA1234567Z
		 */
		'eu_vat'
	] as const,
	SE: [
		/**
		 * European VAT number
		 * @example SE123456789123
		 */
		'eu_vat'
	] as const,
	CH: [
		/**
		 * Switzerland VAT number
		 * @example CHE-123.456.789 MWST
		 */
		'ch_vat'
	] as const,
	TH: [
		/**
		 * Thai VAT
		 * @example 1234567891234
		 */
		'th_vat'
	] as const,
	TR: [
		/**
		 * Turkish Tax Identification Number
		 * @example 0123456789
		 */
		'tr_tin'
	] as const,
	UA: [
		/**
		 * Ukrainian VAT
		 * @example 123456789
		 */
		'ua_vat'
	] as const,
	AE: [
		/**
		 * United Arab Emirates TRN
		 * @example 123456789012345
		 */
		'ae_trn'
	] as const,
	GB: [
		/**
		 * United Kingdom VAT number
		 * @example GB123456789
		 */
		'gb_vat',
		/**
		 * Northern Ireland VAT number
		 * @example XI123456789
		 */
		'eu_vat'
	] as const,
	US: [
		/**
		 * United States EIN
		 * @example 12-3456789
		 */
		'us_ein'
	]
}
