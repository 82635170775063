import { HYDRATE } from 'next-redux-wrapper'
import { isPresent } from 'ts-extras'
import type { Country, Market } from '~/types'
import { getCountryInfo, getMarket } from '~/utils/country'
import type { Action } from '~/utils/redux'

export interface MarketState {
	current: Market | null
	country: Country | null
	markets: Market[]
	countries: Country[]
	selectorOpen: boolean
}

const initialState: MarketState = {
	current: null,
	country: null,
	markets: [],
	countries: [],
	selectorOpen: false
}

export function market(state: MarketState = initialState, action: Action): MarketState {
	switch (action.type) {
		case HYDRATE:
			return {
				...state,
				current: state.current ?? action.payload.market?.current ?? null,
				country: state.country ?? action.payload.market?.country ?? null,
				markets: state.markets.length ? state.markets : action.payload.market?.markets ?? [],
				countries: state.markets.length ? state.countries : action.payload.market?.countries ?? []
			}

		case 'COUNTRY_UPDATE':
			return {
				...state,
				current: getMarket(state.markets, action.payload),
				country: getCountryInfo({ country: action.payload }),
				selectorOpen: false
			}

		case 'MARKETS_UPDATE':
			return {
				...state,
				current: state.current ?? getMarket(action.payload, state.country?.code),
				markets: action.payload,
				countries: action.payload
					.flatMap((m) => m.countries?.map((c) => getCountryInfo({ country: c })))
					.filter(isPresent)
			}

		case 'COUNTRY_SELECTOR_SET':
			return {
				...state,
				selectorOpen: action.payload
			}
	}

	return state
}
