import type Stripe from 'stripe'
import { isPresent, objectEntries } from 'ts-extras'
import type { SetOptional } from 'type-fest'
import type { GQL, Market, Nullable, OrderStatusType, SimpleLineItem } from '~/types'
import { OrderStatus } from '~/types'
import { getPriceDetails } from '../price'

/** Preserves null values if both numbers are null */
function sumNullValues(a: number | null, b: number | null): number | null {
	if (a === null && b === null) return null
	return (a ?? 0) + (b ?? 0)
}

/**
 * Calculate estimated order amount locally
 * @param lineItems
 * @param market
 * @param current If provided, try to calculate tax based on currently known tax details
 * @returns
 * @TODO Support {@link GQL.TaxBehavior} being 'inclusive'
 */
export function calculateOrderAmount(
	lineItems: SimpleLineItem[],
	coupons: Stripe.Coupon[],
	market: Nullable<Market>,
	current?: SetOptional<GQL.PriceDetails, 'invoicing'>
) {
	const details = lineItems
		.map((li) => getPriceDetails(li, coupons, market))
		.filter(isPresent)
		.reduce((a, b) => ({
			...b,
			taxPercent: current?.taxPercent ?? null,
			amountDue: b.amountDue + a.amountDue,
			compareAtPrice: (b.compareAtPrice ?? b.amountDue) + (a.compareAtPrice ?? a.amountDue),
			savings: b.savings + a.savings,
			depositTax: sumNullValues(b.depositTax, a.depositTax),
			depositAmount: sumNullValues(b.depositAmount, a.depositAmount),
			total: b.total + a.total
		}))

	const tax = current?.taxPercent ? (details.total * current.taxPercent) / 100 : details.tax
	return {
		...current,
		...details,
		tax,
		taxPercent: current?.taxPercent ?? details.taxPercent,
		total: current?.taxPercent ? details.total + (tax ?? 0) : details.total,
		transactionID: current?.taxBehavior ?? null,
		depositPercentage: details.depositAmount !== null ? (details.depositAmount / details.subtotal) * 100 : null
	}
}

export function getOrderStatusName<T extends OrderStatusType>(status: T): keyof typeof OrderStatus {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const entry = objectEntries(OrderStatus).find(([, key]) => key === status)!
	return entry[0]
}

export function getOrderStatusEnum(status: keyof typeof OrderStatus): OrderStatusType {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const entry = objectEntries(OrderStatus).find(([key]) => key === status)!
	return entry[1]
}

export function indexOf(status: Nullable<OrderStatusType | GQL.OrderStatus | string>): number {
	if (!status) return -1
	const entries = Object.values(OrderStatus)
	const index = entries.indexOf(status as OrderStatusType)
	return index !== -1 ? index : entries.indexOf(getOrderStatusEnum(status as GQL.OrderStatus))
}
