import type { Store } from 'redux'
import type { SagaMiddleware } from 'redux-saga'
import { call, cancel, delay, fork, take } from 'typed-redux-saga'
import type { State } from '~/types'
import { cartSagas } from './cart'
import { checkoutSagas } from './checkout'

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR'

const sagas = [checkoutSagas, cartSagas]

function makeRestartable(saga: any) {
	return function* () {
		while (true) {
			try {
				yield* call(saga)
				break
			} catch (e) {
				console.warn(`Saga '${saga.name}' failed.`, e)
			}
			yield* delay(1000)
		}
	}
}

// TODO: Add proper typing
function createAbortableSaga(saga: any) {
	if (process.env.NODE_ENV === 'development') {
		return function* main() {
			const sagaTask = yield* fork(saga)

			yield* take(CANCEL_SAGAS_HMR)
			yield* cancel(sagaTask)
		}
	} else {
		return saga
	}
}

const SagaManager = {
	startSagas(sagaMiddleware: SagaMiddleware<any>) {
		sagas
			.map(makeRestartable)
			.map(createAbortableSaga)
			.forEach((saga) => sagaMiddleware.run(saga))
	},

	cancelSagas(store: Store<State>) {
		store.dispatch({
			type: CANCEL_SAGAS_HMR
		})
	}
}

export default SagaManager
