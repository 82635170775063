import type { IterableElement } from 'type-fest'

export type TextStyle = IterableElement<typeof definitions>['value']

/**
 * https://www.figma.com/file/V1BwBJwScnDlxWu8TmA326/Text-Styles?type=design&node-id=5-115&mode=design&t=xD93wbph0LgmK6ht-0
 */
export const definitions = [
	{ title: 'Display xxl', value: 'displayXxl' },
	{ title: 'Display xl', value: 'displayXl' },
	{ title: 'Display lg', value: 'displayLg' },
	{ title: 'Display md', value: 'displayMd' },
	{ title: 'Display sm', value: 'displaySm' },
	{ title: 'Display xs', value: 'displayXs' },

	{ title: 'Heading lg', value: 'headingLg' },
	{ title: 'Heading md', value: 'headingMd' },
	{ title: 'Heading sm', value: 'headingSm' },
	{ title: 'Title lg', value: 'titleLg' },
	{ title: 'Title md', value: 'titleMd' },
	{ title: 'Title sm', value: 'titleSm' },

	{ title: 'Text xl', value: 'textXl' },
	{ title: 'Text lg', value: 'textLg' },
	{ title: 'Text md', value: 'textMd' },
	{ title: 'Text sm', value: 'textSm' },

	{ title: 'Body xl', value: 'bodyXl' },
	{ title: 'Body lg', value: 'bodyLg' },
	{ title: 'Body md', value: 'bodyMd' },
	{ title: 'Body sm', value: 'bodySm' },

	{ title: 'Overline md', value: 'overlineMd' },
	{ title: 'Overline sm', value: 'overlineSm' },

	{ title: 'Lead', value: 'lead' },
	{ title: 'Footnote', value: 'footnote' },

	// Deprecated (old Design System)
	{ title: '(deprecated) Display lg', value: 'display1' },
	{ title: '(deprecated) Display md', value: 'display2' },
	{ title: '(deprecated) Display sm', value: 'display3' },
	{ title: '(deprecated) Heading 1', value: 'h1' },
	{ title: '(deprecated) Heading 2', value: 'h2' },
	{ title: '(deprecated) Heading 3', value: 'h3' },
	{ title: '(deprecated) Heading 4', value: 'h4' },
	{ title: '(deprecated) Heading 5', value: 'h5' },
	{ title: '(deprecated) Heading 6', value: 'h6' },
	{ title: '(deprecated) Body', value: 'body' },
	{ title: '(deprecated) Body sm', value: 'small' }
] as const

export const lineHeights = {
	heading: '115%',
	default: 'default',
	body: '140%'
} as const

export const fontFamilies = {
	serif: 'Tiempos Text, serif',
	sans: 'Nomono Sans, sans-serif'
} as const

export const letterSpacings = {
	default: '-0.01em',
	wide: '0.06em'
} as const

export const fontSizes = {
	1: '12px',
	2: '13px',
	3: '15px',
	4: '17px',
	5: '20px',
	6: '27px',
	7: '36px',
	8: '47px',
	9: '63px',
	10: '84px',
	11: '112px'
} as const

// These are Sanity Studio styles only. Styles used are defined by classNames in serializers.tsx.
export const textStyles: Record<TextStyle, React.CSSProperties> = {
	displayXxl: createStyle('serif', 'normal', 'heading', 11),
	displayXl: createStyle('serif', 'normal', 'heading', 10),
	displayLg: createStyle('serif', 'normal', 'heading', 9), // former <Heading  type="display1" />
	displayMd: createStyle('serif', 'normal', 'heading', 8), // former <Heading  type="display2 />
	displaySm: createStyle('serif', 'normal', 'heading', 7), // former <Heading  type="display3 and h1 />
	displayXs: createStyle('serif', 'normal', 'heading', 6), // former <Heading  type="h2 | h3" />

	headingLg: createStyle('sans', '500', 'default', 7),
	headingMd: createStyle('sans', '500', 'default', 6),
	headingSm: createStyle('sans', '500', 'default', 5), // former <Heading  type="h4 />
	titleLg: createStyle('sans', '500', 'default', 4), // former <Heading  type="h5 />
	titleMd: createStyle('sans', '500', 'default', 3), // former <Heading  type="h6 />
	titleSm: createStyle('sans', '500', 'default', 2),

	textXl: createStyle('sans', 'normal', 'default', 5),
	textLg: createStyle('sans', 'normal', 'default', 4),
	textMd: createStyle('sans', 'normal', 'default', 3),
	textSm: createStyle('sans', 'normal', 'default', 2),

	bodyXl: createStyle('sans', 'normal', 'body', 5),
	bodyLg: createStyle('sans', 'normal', 'body', 4), // former <Body />
	bodyMd: createStyle('sans', 'normal', 'body', 3), // former <Small />
	bodySm: createStyle('sans', 'normal', 'body', 2),

	overlineMd: createStyle('sans', '500', 'default', 2, 'uppercase', 'wide'),
	overlineSm: createStyle('sans', '500', 'default', 1, 'uppercase', 'wide'),

	// Deprecated?
	lead: createStyle('sans', 'normal', 'body', 5),
	footnote: createStyle('sans', 'normal', 'body', 2),

	/**
	 * @deprecated When all pages and (especially) knowledge articles are updated in Sanity Studio to use new fonts, these could be removed.
	 */
	display1: createStyle('serif', 'normal', 'heading', 11),
	display2: createStyle('serif', 'normal', 'heading', 10),
	display3: createStyle('serif', 'normal', 'heading', 9),
	h1: createStyle('serif', 'normal', 'default', 8),
	h2: createStyle('serif', 'normal', 'default', 7),
	h3: createStyle('serif', 'normal', 'default', 6),
	h4: createStyle('sans', '500', 'default', 5),
	h5: createStyle('sans', '500', 'default', 4),
	h6: createStyle('sans', '500', 'default', 3),
	body: createStyle('sans', 'normal', 'body', 4),
	small: createStyle('sans', 'normal', 'body', 3)
	// End Deprecated
	// ----------
}

export function createStyle(
	fontFamily: keyof typeof fontFamilies,
	fontWeight: React.CSSProperties['fontWeight'],
	lineHeight: keyof typeof lineHeights,
	fontSize: keyof typeof fontSizes,
	textTransform: React.CSSProperties['textTransform'] = 'none',
	letterSpacing: keyof typeof letterSpacings = 'default'
): React.CSSProperties {
	return {
		fontFamily: fontFamilies[fontFamily],
		fontWeight,
		lineHeight: lineHeights[lineHeight],
		fontSize: fontSizes[fontSize],
		textTransform,
		letterSpacing: letterSpacings[letterSpacing]
	}
}
