import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import type { UseQueryResult } from '@tanstack/react-query'
import { QueryCache, QueryClient } from '@tanstack/react-query'
import type { GQL } from '~/types'
import { getDocumentName } from './gqlClient'

export const queryCache = new QueryCache()

export const queryClient = new QueryClient({ queryCache })

export function cacheKey<T, V>(query: TypedDocumentNode<T, V>) {
	return getDocumentName(query) ?? query
}

export function extractQueryResponse<T extends UseQueryResult<any, any>>(response: T) {
	const error = response.error
	const errors = response.data?.errors ?? (error ? [error as GQL.ResponseError] : null)
	return {
		...response,
		errors: errors?.length ? errors : null,
		data: response.data?.data
	}
}
