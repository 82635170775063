import avalaraSchema from './avalara.gql'
import ordersSchema from './orders.gql'
import plan from './plan.gql'
import sanitySchema from './sanity.gql'
import scalarsSchema from './scalars.gql'
import baseSchema from './schema.gql'
import stripeSchema from './stripe.gql'

// Ensure all schemas are included in the final schema
export const schema = [baseSchema, plan, scalarsSchema, ordersSchema, sanitySchema, stripeSchema, avalaraSchema].join(
	'\n'
)
