import { createClient } from 'next-sanity'
import type { Nullable, PreviewData } from '~/types'
import { DATASET, IS_NEXT_PREVIEW } from '../constants'
import { SanityConfig } from './config'

// Set up the client for fetching data in the getProps page functions
const sanityClient = createClient(SanityConfig)

// Set up a preview client with serverless authentication for drafts
const previewClient = createClient({
	...SanityConfig,
	useCdn: false
})

// Helper function for easily switching between normal client and preview client
export function getClient(usePreview?: Nullable<boolean>, config?: Nullable<PreviewData>) {
	// TODO: Use NextJS' previewData instead of heuristics
	const isPreview = usePreview ?? IS_NEXT_PREVIEW() ?? false
	const dataset = config?.dataset ?? DATASET()
	const client = isPreview || dataset === 'development' ? previewClient : sanityClient
	return client.withConfig({ dataset })
}
