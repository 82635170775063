import { Filter } from '@hubspot/api-client/lib/codegen/crm/deals'
import type { Except, SetRequired } from 'type-fest'
import type { Hubspot } from './hubspot.generated'

export interface HubspotCompany {
	id: string
	name: string
	tax_id?: string
}

export type HubspotContact = SetRequired<Hubspot.Contact['properties'], 'email' | 'firstname'>

export enum ObjectTypeId {
	Contact = '0-1',
	Company = '0-2',
	Deal = '0-3',
	Ticket = '0-5'
}

export interface WithAssociations<TKey extends keyof Hubspot.Associations> {
	'associations'?: {
		[K in TKey]: Hubspot.Associations[K]
	}
}

export interface HubspotError {
	status: string
	message: string
	correlationld: string
	category: string
}

export type HubspotFilter<
	T extends { 'properties': Record<string, any> },
	K extends keyof T['properties'] = keyof T['properties']
> = Except<Filter, 'propertyName' | 'value'> & {
	propertyName: K
	value?: T['properties'][K]
}
