import { isArray, isObject } from 'lodash'
import type { GetServerSidePropsContext } from 'next'
import type { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import type { NextRequest } from 'next/server'
import { createContext } from 'react'
import type {
	CheckoutSettings,
	Country,
	IGlobalContext,
	KbSettings,
	LegalSettings,
	Nullable,
	Page,
	SiteSettings
} from '~/types'
import { getCountryInfo } from './country'

/**
 * Returns whether a page has product information.
 * Mostly used for knowing whether you need a Country Selector or not
 * @param page
 * @returns
 */
export function isProductPage(page: Nullable<Page>) {
	switch (page?._type) {
		case 'frontPage':
			return page.contentBlocks?.some((block) => block._type === 'productBlock')

		default:
			return false
	}
}

export const GlobalContext = createContext<IGlobalContext>({
	markets: [],
	checkoutSettings: {} as CheckoutSettings,
	siteSettings: {} as SiteSettings,
	kbSettings: {} as KbSettings,
	legalSettings: {} as LegalSettings,
	plans: []
})

/**
 * Gets preferred country
 * @param req pass in to provide access to server side information (cookies or geolocation)
 * @param query (appended to query by middleware)
 * @returns Return used-preferred country if cookie is set, otherwise use geolocation.
 * If geolocation is not available, return null
 */
export function getServerCountry(
	query?: NextParsedUrlQuery,
	req?: GetServerSidePropsContext['req'] | string
): Country | null {
	// Country info from Vercel Geolocation

	const serverCountry = isArray(query?.country) ? query?.country[0] : query?.country
	const suggestedCountry: string | undefined =
		serverCountry ?? (isObject(req) ? (req as unknown as NextRequest).geo?.country : undefined)
	const country = suggestedCountry ? getCountryInfo({ country: suggestedCountry }) : null

	// slug is sometimes appeneded to data by next.js
	delete (country as any)?.slug
	// If object is empty, return null instead
	return Object.keys(country ?? {}).length > 0 ? country : null
}
