import { HYDRATE } from 'next-redux-wrapper'
import type { FilterActions, GetMetaActions } from '~/types'
import type { CartState } from '../reducers/cart'
import type { MarketState } from '../reducers/market'
import { makeActionCreator } from './actionCreator'
import CartActions from './cart'
import CheckoutActions from './checkout'
import MarketActions from './market'

export const Actions = {
	...CartActions,
	...CheckoutActions,
	...MarketActions,
	updateRoute: makeActionCreator<URL, boolean>()('UPDATE_ROUTE'),

	// Should not be used manually, is only added to get typing in reducers
	__hydrate: makeActionCreator<Partial<{ cart: CartState; market: MarketState }>>()(HYDRATE)
}

export type ActionCreator = (typeof Actions)[keyof typeof Actions]
type A = ReturnType<ActionCreator>

export type Action<TKey extends Action['type'] = any, TAction extends A = A> = TAction extends { type: TKey }
	? TAction
	: never

export type ErrorAction = FilterActions<Action, 'ERROR'>
export type SuccessAction = FilterActions<Action, 'SUCCESS'>

export type MetaAction = GetMetaActions<Action>
export type ActionTypes<TSearch = null> = TSearch extends string
	? FilterActions<Action, TSearch>['type']
	: Action['type']

export interface AnyAction {
	[extraProps: string]: any
	type: ActionTypes
}
