import type { GQL, Step } from '~/types'
import type { BillingFormValues, CheckoutFormValues, PaymentFormValues } from '~/utils/checkout'
import type { ShippingFormValues } from '~/utils/checkout/ShippingForm.config'
import type { CheckoutState } from '../reducers/checkout'
import { makeActionCreator } from './actionCreator'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	updateCheckoutFormField: makeActionCreator<
		CheckoutFormValues[keyof CheckoutFormValues],
		keyof CheckoutFormValues
	>()('CHECKOUT_FORMFIELD_UPDATE'),

	setCheckoutID: makeActionCreator<string>()('CHECKOUT_ID_UPDATE'),

	setStep: makeActionCreator<Step>()('CHECKOUT_STEP_UPDATE'),
	setPaymentMethod: makeActionCreator<GQL.PaymentMethod>()('CHECKOUT_SET_PAYMENT_METHOD'),

	validateUser: makeActionCreator<Pick<GQL.User, 'email'>>()('CHECKOUT_VALIDATE_USER'),
	validateShipping: makeActionCreator<ShippingFormValues>()('CHECKOUT_VALIDATE_SHIPPING'),
	validateBilling: makeActionCreator<BillingFormValues>()('CHECKOUT_VALIDATE_BILLING'),
	validatePayment: makeActionCreator<PaymentFormValues>()('CHECKOUT_VALIDATE_PAYMENT'),

	setValidationResult: makeActionCreator<
		CheckoutState['details'][keyof CheckoutState['details']],
		keyof CheckoutState['details']
	>()('CHECKOUT_VALIDATE_RESULT'),

	checkoutRetrieved: makeActionCreator<GQL.CheckoutResponse>()('CHECKOUT_RETRIEVED')
}
