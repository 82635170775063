import { getCookie as nextGetCookie, hasCookie as nextHasCookie, setCookie as nextSetCookie } from 'cookies-next'
import { Dataset } from '~/types'

export type CookieOptions = NonNullable<Parameters<typeof nextGetCookie>[1]>

interface CookieTypeMap {
	ref: string
	dataset: Dataset
	is_preview: boolean
	cje: string
	travel: boolean
	utm_source: string
	utm_medium: string
	utm_campaign: string
}

export function getCookie<T extends keyof CookieTypeMap>(
	name: T,
	options: CookieOptions = {}
): CookieTypeMap[T] | null {
	const value = nextGetCookie(name, { path: '/', ...options }) ?? null
	return value as CookieTypeMap[T] | null
}

export function setCookie<T extends keyof CookieTypeMap>(
	name: T,
	value: CookieTypeMap[T],
	options: CookieOptions = {}
) {
	nextSetCookie(name, value, { path: '/', ...options })
}

export function hasCookie(name: keyof CookieTypeMap, options: CookieOptions = {}) {
	nextHasCookie(name, { path: '/', ...options })
}
