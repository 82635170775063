import { buildFileUrl } from '@sanity/asset-utils'
import { objectEntries } from 'ts-extras'
import { MediaType } from '~/types'
import { except } from '../helpers'
import { SanityConfig } from './config'

export function assetUrl(media: MediaType | MediaType[]): string {
	const asset = Array.isArray(media) ? media[0] : media
	switch (asset._type) {
		case 'externalVideo': {
			const url = new URL(`https://player.vimeo.com/video/${asset.id}`)
			objectEntries(except(asset, 'width', 'height')).forEach(
				([key, value]) =>
					value && url.searchParams.set(key, typeof value === 'boolean' ? (value ? '1' : '0') : value)
			)
			return url.toString()
		}
		case 'youtubeVideo': {
			const url = new URL(`https://www.youtube.com/embed/${asset.id}`)

			// TODO: also use the new excepted keys for externalVideo
			// @ts-expect-error '_key' is not part of the generated type, but it's still present there
			objectEntries(except(asset, 'width', 'height', '_type', 'id', '_key')).forEach(
				([key, value]) =>
					value && url.searchParams.set(key, typeof value === 'boolean' ? (value ? '1' : '0') : value)
			)

			return url.toString()
		}

		default:
			// TODO: Implement better type checking
			return buildFileUrl(asset.asset as any, SanityConfig)
	}
}
